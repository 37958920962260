@use 'base/variables' as v;
@use 'abstract/mixins' as m;

.modal {
  &-header {
    padding: 32px 32px 0;
    border: none;
    color: v.$black-color;
    justify-content: space-between;

    &--order {
      justify-content: flex-start;
      padding: 16px 32px;
      border-bottom: solid 1px v.$input-border-color;
    }
  }

  &-title {
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;

    &--order {
      font-size: 16px;
      line-height: 24px;
    }
  }

  &-body {
    padding: 24px 32px 20px;

    &--order {
      padding: 32px 32px 16px;
    }

    .slider-form & {
      padding-bottom: 0;
    }
  }

  &-close-icon {
    margin: 8px 0 0 8px;
    align-self: flex-start;

    &--order {
      margin: 4px 12px 0 0;
    }
  }

  &-description {
    font-size: 14px;
    line-height: normal;
    color: v.$black-color;
    margin: 0;
  }

  &-footer {
    border: none;
    padding: 16px 32px 24px;

    .btn {
      &:last-child {
        margin-left: 16px;
      }
    }
  }
}

.admin-input {
  &__label {
    margin-top: 4px;
    padding-right: 34px;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    color: v.$black-color;
  }

  &__input-wrapper {
    position: relative;
    margin-bottom: 20px;

    input:placeholder-shown + .admin-input__counter {
      visibility: hidden;
      opacity: 0;
    }
  }

  &__input {
    display: block;
    width: 100%;
    height: 32px;
    margin-top: 4px;
    padding: 6px 12px;
    border: 1px solid v.$input-border-color;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: v.$black-color;

    &::placeholder {
      color: v.$middle-grey-color;
    }

    &--invalid {
      border-color: v.$red-color;
    }
  }

  &__error {
    display: inline-block;
    position: absolute;
    left: 0;
    top: calc(100% + 8px);
    color: v.$red-color;
    font-size: 12px;
  }

  &__counter {
    position: absolute;
    right: 0;
    top: -18px;
    font-size: 12px;
    line-height: 16px;
    color: v.$dark-grey-color;
    opacity: 1;
    visibility: visible;
    transition: all ease-in-out 0.4s;
  }
}

.alert-dialog {
  &.modal-dialog {
    position: absolute;
    right: 0;
    top: 0;
    width: 40%;
    margin: 0;
    min-height: unset;

    .app-tablet &,
    .app-mobile & {
      position: relative;
      right: auto;
      margin: 0 auto;
    }

    .app-mobile & {
      width: 375px;
    }

    .app-tablet & {
      width: 768px;
    }
  }
}
