@use 'base/variables' as v;
@use 'abstract/mixins' as m;

.carousel-card {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  position: relative;
  align-items: stretch;
  min-height: 400px;

  &.admin-card {
    padding-top: 32px;
  }

  &:not(&.admin-card) {
    .carousel-card__info {
      color: v.$black-color;
    }
  }

  &--alerts-present {
    .carousel-card {
      &__title {
        font-size: 18px;
      }
      &__title,
      &__description {
        &:hover {
          color: v.$black-color;
        }
      }
    }
  }

  &--horizontal {
    min-height: auto;

    .carousel-card__description {
      line-height: 19px;
    }

    .carousel-card__slide {
      flex-direction: row;

      .carousel-card__img {
        display: block;
        margin-bottom: 0;
        margin-right: 16px;
        min-width: 50%;
        width: 50%;
      }
    }

    .carousel-card {
      &__title {
        font-size: 18px;
        line-height: 22px;
      }
    }
  }

  &__content {
    flex: 1;
  }

  &__slide {
    max-width: 434px;
    padding: 0 32px;
    display: flex;
    flex-direction: column;
    color: inherit;

    .preview-mode-tablet & {
      max-width: 100%;
    }
  }

  &__img {
    margin-bottom: 12px;

    img {
      width: 100%;
    }
  }

  &__icon {
    width: 68px;
    height: 68px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__title {
    display: block;
    color: inherit;
    text-decoration: none;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 12px;

    .app-mobile & {
      margin-bottom: 8px;
    }

    &--truncated {
      width: 100%;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }

  &__description {
    display: block;
    color: inherit;
    text-decoration: none;
    line-height: 20px;
    word-break: break-word;

    &--truncated {
      width: 100%;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  &__navigation {
    padding: 20px 32px 36px;
  }

  &__link {
    margin-top: 12px;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: v.$blue-color;
    font-weight: 600;
    @include m.learn-more-link-size;

    &:hover {
      color: v.$blue-color;

      .carousel-card__link__icon {
        transform: translateX(3px);
      }
    }

    &__icon {
      display: flex;
      align-items: center;
      transition: transform linear 0.2s;
    }

    app-icon {
      width: 14px;
      height: 14px;
    }
  }
}

.cards-row--wide {
  &:not(.preview-mode-tablet &):not(.preview-mode-mobile &):not(.section-body--mini-carousel &) {
    .carousel-card {
      min-height: 200px;

      &__slide {
        max-width: 100%;
        flex-direction: row;
      }

      &__info {
        flex: 1;
        margin-left: 16px;
      }

      &__img {
        margin-bottom: 0;
        max-width: 268px;
        min-width: 268px;

        &.with-icon {
          min-width: 68px;
        }
      }

      &__icon {
        min-width: 68px;
      }
    }
  }
}
