@use 'admin/base/colors' as colors;

$side-bar-and-border-width: 290px;
$border-size: 8px;
$form-footer: 81px;

.admin-footer {
  bottom: $border-size;
  transition: 0.3s margin-bottom ease-in-out;
  &--bottom-offset {
    margin-bottom: $form-footer;
  }

  &--bottom-sticked {
    position: absolute;
  }

  footer {
    background-color: colors.$white;
    border-top: 1px solid colors.$cool-gray-2;
    width: 100%;
    z-index: 10;
  }
}
