@use 'base/variables' as v;
@use 'abstract/mixins' as m;

.admin {
  &-link {
    &:hover {
      text-decoration: underline;
    }

    .admin-link-icon {
      width: 14px;
      height: 14px;
      margin-left: 5px;
    }
  }

  &-description {
    &.NgxEditor__Content {
      padding: 0;
      min-height: auto;
    }
  }
}

.section {
  &-title {
    font-weight: 600;
    font-size: 24px;
    line-height: 1.3334;

    .app-mobile & {
      margin-bottom: 0;
    }
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .app-mobile & {
      flex-wrap: wrap;
      align-items: baseline;
      gap: 12px;
    }

    .section-link-blk {
      .app-mobile & {
        margin-left: 0 !important;
      }
    }
  }
}
