@use 'variables' as v;
@use 'abstract/mixins' as m;

.dragging-ghost {
  opacity: 0.4;

  .admin-news {
    &__image {
      width: 300px;
    }
  }

  .section-card__content-wrapper {
    &.hover-state-enabled {
      box-shadow: 0 0 0 3px v.$green-color !important;
    }
  }

  .card-options {
    display: flex !important;
  }
}

.draggable {
  user-select: none;
  height: 100%; //for full height card of section
}

.release:not(.dragging) {
  transition: transform 200ms ease-in-out;
}

.dropzone-activated {
  border: 1px dashed #2ca6b4 !important;
}

.dropzone-entered {
  background-color: #c8edf7 !important;
}

.dragging-placeholder {
  width: 100px;
  padding: 10px 20px;
  cursor: move;
  background-color: orange;
}

.cdk-overlay-pane {
  pointer-events: none !important;
}

.box-to-drag {
  background-color: green;
  padding: 10px 20px;
  width: 100px;
  border: 1px solid;
  margin-left: 100px;
}

.cards-row {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  position: relative;
  padding: 1px 1px 4px 4px;
  @media only screen and (width>=m.$custom-hero-1440) {
    padding: 0;
  }

  &:not(:first-child) {
    margin-top: 16px;
  }

  .preview-mode & {
    &:first-child {
      margin-top: 0;
    }
  }

  &.about-vo-gcu {
    &:not(&.about-vo-small-view) {
      @include m.respond-to(Hero1600) {
        .gcu,
        .vo-card-empty {
          flex-direction: column;
        }
      }
      @include m.respond-to(Hero1200) {
        flex-direction: column;
        margin: 0 20px -20px;

        .helper {
          margin-left: 0;
          margin-bottom: 20px;
        }

        .gcu,
        .vo-card-empty {
          flex-direction: row;
        }

        .preview-mode-mobile & {
          .gcu,
          .vo-card-empty {
            flex-direction: column;
          }
        }
      }
    }

    .preview-mode-mobile & {
      .gcu,
      .vo-card-empty {
        flex-direction: column;
      }
    }
  }

  &.about-vo-small-view {
    display: flex;

    .helper + .helper {
      @media only screen and (width > m.$custom-hero-1200) {
        max-width: 377px;
      }
    }

    @include m.respond-to(Hero1200) {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      margin: 0 20px -20px;

      .helper {
        margin-left: 0;
        margin-bottom: 20px;
      }
    }

    .preview-mode-tablet & {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      margin: 0 20px -20px;

      .helper {
        margin-left: 0;
        margin-bottom: 20px;
      }

      .helper + .helper {
        max-width: 100%;
      }
    }

    .preview-mode-mobile & {
      display: flex;
    }
  }
}

.card {
  height: 200px;
  background-color: gray;
  flex: 1;
}

.drop-zone {
  background: v.$light-blue-color;
  border: 1px dashed v.$blue-color;

  &__horizontal {
    position: absolute;
    top: -18px;
    z-index: 2;
    width: 100%;
    height: 16px;
    left: 5px;
  }

  &__vertical {
    width: 14px;
    position: absolute;
    height: 100%;
    left: -17px;
  }
}

.last-drag-zone {
  position: relative;
  height: 16px;
  left: 5px;
}

.helper {
  flex: 1;
  margin-left: 20px;
  position: relative;
  min-width: 0;

  .slider-enabled & {
    margin: 0;
  }
}

.second-helper {
  width: 18px;
  position: relative;
  align-self: stretch;
}

.second-helper .drop-zone__vertical {
  width: 16px;
  position: absolute;
  right: 0;
  left: 3px;
  height: 100%;
}
