* {
  box-sizing: border-box;
  outline: none;
}

html,
body {
  height: 100%;
  margin: 0 auto;
  font-family: 'Open Sans', sans-serif;
  background: #ffffff;
  font-size: 14px;
}

.wrapper-content {
  padding: 24px 40px 50px;
}

// helpers
.ml-6 {
  margin-left: 6px;
}

.ml-8 {
  margin-left: 8px;
}

.ml-20 {
  margin-left: 20px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-12 {
  margin-right: 12px;
}

.justify-content-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}

.pointer-events-none {
  pointer-events: none;
}

.disabled {
  opacity: 0.4;
  pointer-events: none;
}

.fw-600 {
  font-weight: 600 !important;
}
