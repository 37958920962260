@use 'base/variables' as v;
@use './abstract/mixins' as m;

.lfh {
  position: relative;
  display: flex;
  margin-bottom: 32px;
  width: 90%;
  max-width: 320px;

  &-toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 32px;
    padding: 6px 12px;
    border: 1px solid #d0d0ce;
    font-size: 14px;
    line-height: 19px;
  }

  &-dropdown {
    color: v.$black-color;
    display: none;
    width: 100%;
    height: auto;
    position: absolute;
    background: #fff;
    right: 0 !important;
    border: solid 1px v.$light-grey-color-2;
    top: 31px !important;
    cursor: default;
    overflow-y: auto;
  }

  &-title {
    font-weight: 600;
    font-size: 24px;
    line-height: 1.33334;
    margin-bottom: 16px;
  }

  &-list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    column-gap: 40px;
    height: auto;
  }

  &-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 32px;
    font-size: 14px;
    line-height: 19px;
    padding: 6px 12px;

    &:hover {
      cursor: pointer;
      background-color: v.$gray-13;
    }

    &--active {
      background-color: v.$gray-12;

      &:hover {
        cursor: default;
        pointer-events: none;
      }
    }
  }

  &.open {
    .lfh-toggle__caret {
      transform: rotate(180deg);
    }
    .lfh-dropdown {
      display: block;
    }
  }

  &-help-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .preview-mode-mobile & {
      flex-direction: column;
      flex-wrap: nowrap;
    }

    &-item {
      line-height: 20px;

      &:nth-child(-n + 3) {
        margin-bottom: 40px;
      }
    }

    &-col {
      width: calc(33.33% - 10px);
      min-width: 0;

      &--empty {
        .preview-mode-tablet &,
        .preview-mode-mobile & {
          display: none;
        }
      }

      .preview-mode-tablet & {
        width: calc(50% - 10px);
      }

      .preview-mode-mobile & {
        width: 100%;
      }
    }

    .uc-about-help-list-item__text {
      line-height: 20px;
    }

    .uc-about-help-list-item__title {
      font-size: 14px;
      line-height: 20px;
      font-weight: 600;
      margin-bottom: 8px;
    }

    br {
      display: none;
    }

    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
