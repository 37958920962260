.popover {
  position: absolute;
  top: 0;
  left: 0 #{'/* rtl:ignore */'};
}

.popover-arrow {
  position: absolute;
}

.tooltip {
  position: absolute;
}

.tooltip-arrow {
  position: absolute;
}

.edit-section {
  &__info-icon-tooltip {
    .tooltip-inner {
      position: relative;
      right: 15px;
    }
  }
}
