.section-card {
  &.about-leader-news {
    height: 100%;

    .leader-news {
      display: block;
      height: 100%;
    }

    .app-admin-news {
      display: block;
      height: 100%;

      .admin-news {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
      }
    }
  }
}

.sections-container {
  &__list-orientation {
    .owl-stage {
      display: flex;

      .cards-row {
        //height: calc(100% - 40px);
      }
    }
  }
}

.cards-row {
  .owl-item & {
    height: 100%;

    .slider-enabled & {
      padding: 1px 10px 4px 10px;
    }
  }
}

.sections-container > owl-carousel-o {
  > .owl-carousel > .owl-stage-outer {
    padding-top: 34px;
    margin-top: -34px;
  }
}

.preview-mode-mobile,
.preview-mode-tablet {
  .sections-container__list-orientation {
    margin: 0 -4px;

    .owl-stage .owl-item {
      margin: 0 4px;
    }
  }
}
