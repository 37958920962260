@use 'admin/base/colors' as colors;

.navigation {
  position: relative;
  width: 290px;
  height: 100%;
  background: colors.$light-gray;

  app-navigation-list {
    width: 100%;
    display: flex;
    max-height: calc(100% - 76px);
    overflow: hidden;
    overflow-y: auto;
  }

  &-list {
    padding: 16px 24px;
    width: 100%;
    height: 100%;

    &__accordion .dds-accordion__item {
      .dds-accordion__header {
        padding: 0;
        border: none;
        background: transparent;

        .dds-accordion__icon {
          align-self: center;
          margin: 2px 12px 0 4px;
          color: colors.$white;
        }
      }

      .dds-accordion__body {
        padding: 0;
        border: none;
        background: transparent;

        .navigation-list__item .dds-btn .dds-btn__content .dds-btn__text {
          padding-left: 32px;
        }
      }
    }

    &__item {
      .dds-btn {
        &__content {
          justify-content: flex-start;
          font-weight: 600;
        }
      }
    }
  }
}

// Collapsed View
.navigation.collapsed {
  width: 40px;

  app-navigation-list {
    width: 40px;
    max-height: 100%;
  }

  .navigation-list {
    padding: 0;
    width: 100%;
    height: 100%;
    margin-top: 76px;
    overflow: hidden;

    li > dds-button > button {
      .dds-btn__content {
        padding: 10px;
      }
    }
  }
}

.navigation.manageSidebar {
  position: absolute;
  z-index: 10;
}

// Themes
@mixin navigation-theme($color, $active-color) {
  .navigation {
    &-list {
      &__accordion .dds-accordion__item {
        .dds-accordion__header {
          &:has(.navigation-list__item--active) {
            background-color: $active-color;
            box-shadow: inset 0 0 0 1px $active-color;
          }
        }
      }

      &__item {
        &--active {
          .dds-btn {
            background-color: $active-color;
            box-shadow: inset 0 0 0 1px $active-color;
          }
        }
      }
      background: $color;
    }
  }
}

.admin-theme--green {
  @include navigation-theme(colors.$accessible-green, colors.$accessible-green_active);
}

.admin-theme--teal {
  @include navigation-theme(colors.$accessible-teal, colors.$accessible-teal_active);
}

.admin-theme--blue {
  @include navigation-theme(colors.$accessible-blue, colors.$accessible-blue_active);
}
