@use 'base/variables' as v;
@use './abstract/mixins' as m;

@mixin preview-reset {
  .selected-cards-container {
    display: block;
  }

  .cards-container,
  .cards-row,
  .helper {
    margin: 0;
  }

  .cards-row {
    flex-direction: column;
  }

  .second-helper {
    width: 0;
  }

  .helper {
    width: 100%;
  }

  app-card {
    margin: 0;
  }

  .section-card__content-wrapper {
    border-width: 0;
  }
}

@mixin preview-tablet {
  .section-body {
    padding: 80px 32px;
  }

  .cards-row {
    padding: 0;

    .helper {
      margin-bottom: 24px;
    }
  }

  .promo-wrapper {
    padding: 32px 24px;
    height: auto;
  }

  .page-dws-header {
    height: 112px;
  }

  .sections-wrapper .section-body--grid {
    display: block !important;
  }

  .sections-wrapper .section-body--grid.section-body--mini-carousel--left {
    display: flex !important;
    flex-direction: column;
    padding-top: 0;

    .secondary-flow-block__pseudo-wrapper:after {
      top: -48px;
      height: calc(100% + 128px);
    }

    .main-flow-block {
      margin-top: 160px;
    }
  }

  .dt-newsletter {
    &-title {
      font-weight: 600;
    }
  }
}

@mixin preview-mobile {
  .section-body {
    padding: 60px 24px;
  }

  .cards-row {
    padding: 0;

    .helper {
      margin-bottom: 16px;
    }
  }

  .promo-wrapper {
    padding: 20px;
    height: auto;
  }

  .page-dws-header {
    height: 104px;
  }

  .about-news-host {
    .feature-news {
      flex-direction: column;
    }

    .non-feature-news {
      grid-template-columns: 1fr;
    }
  }

  .sections-wrapper .section-body--grid {
    display: block !important;
  }

  .sections-wrapper .section-body--grid.section-body--mini-carousel--left {
    display: flex !important;
    flex-direction: column;
    padding-top: 0;

    .secondary-flow-block__pseudo-wrapper:after {
      top: -48px;
    }

    .main-flow-block {
      margin-top: 120px;
    }
  }
}

.preview-mode {
  margin: 0 auto;

  .section-body:not(.section-body__top-banner):not(.section-body--mini-carousel) {
    border-bottom: 1px solid #dbdbdb;
    @media only screen and (width>m.$custom-section-divider) {
      border-bottom: none;
      &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        max-width: v.$fixed-layout-max-template-width;
        margin: 0 auto;
        height: 1px;
        background-color: #dbdbdb;
      }
    }
  }

  .admin-news {
    &__tag {
      cursor: pointer;
    }
  }

  &-tablet {
    width: v.$breakpoint__tablet;

    .app-tablet & {
      @include preview-reset;
      @include preview-tablet;
    }
  }

  &-mobile {
    width: v.$breakpoint__mobile;

    .app-mobile & {
      @include preview-reset;
      @include preview-mobile;
    }
  }

  &-desktop {
    .section-body__top-banner {
      .helper {
        min-width: 100% !important;
        margin: 0 !important;
      }

      .cards-container {
        margin: 0 !important;
      }

      .cards-row {
        padding: 0 !important;
      }
    }
  }
}
