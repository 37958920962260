@use 'base/variables' as v;
@use 'abstract/mixins' as m;

.admin-news {
  text-decoration: none;

  &__image {
    border: 1px solid v.$input-border-color;
    height: auto;
    width: 100%;
    cursor: pointer;
  }

  &__card-body {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &__tag-and-options {
    line-height: 20px;
    margin-top: auto;
    display: flex;
    align-items: center;
  }

  &__leader-options {
    line-height: 20px;
  }

  &__leader-name {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    display: flex;
    align-items: center;

    &:hover {
      .admin-news {
        &__info-option {
          background: v.$accessible-teal;

          app-icon {
            color: v.$white-color;
          }
        }

        &__leader-name-text {
          color: v.$black-bg-teal;
          text-decoration: underline;
        }
      }
    }

    .preview-mode-mobile & {
      font-size: 18px;
      line-height: 24px;
    }
  }

  &__leader-title {
    margin-top: 12px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;

    .preview-mode-mobile & {
      font-size: 16px;
      line-height: 24px;
    }

    .preview-mode-tablet & {
      font-size: 18px;
      line-height: 24px;
    }
  }

  &__leader-description {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-top: 4px;
  }

  &__leader-body {
    display: flex;
    flex: 1;
    justify-content: space-between;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  &__info-option {
    $info-icon-width: 16px;

    width: $info-icon-width;
    height: 20px;
    display: flex;
    align-items: center;
    margin-right: 8px;
    background: inherit;

    .preview-mode-tablet & {
      margin-right: 12px;
    }

    app-icon {
      height: 12px;
      width: $info-icon-width;
      color: inherit;
    }

    svg {
      height: 15px;
    }
  }

  &__title {
    margin-top: 16px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;

    @include m.respond-to(Hero1440) {
      font-size: 14px;
      line-height: 20px;
      margin-top: 8px;
    }

    .preview-mode-tablet &,
    .preview-mode-mobile & {
      font-size: 14px;
      line-height: 20px;
    }
  }

  &__subtitle {
    margin-top: 4px;
    line-height: 20px;
    margin-bottom: 8px;
  }

  &__tag {
    color: v.$accessible-teal;
    display: inline-block;
    padding: 0 16px 0 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;

    .app-tablet & {
      padding: 0 12px 0 0;
    }

    &:hover {
      color: v.$accessible-teal;
    }

    &:active,
    &:focus {
      color: v.$accessible-teal;
    }
  }

  .admin-news__share-option {
    width: 14px;
    height: 14px;
    color: inherit;

    &--gray {
      color: inherit;
    }

    &:hover {
      cursor: pointer;
      color: v.$accessible-teal;
    }
  }

  .admin-news__share {
    height: 14px;

    .show {
      .admin-news__share-option {
        color: v.$accessible-teal;
      }
    }
  }
}

.non-feature-news {
  &__feature-item {
    .admin-news {
      display: flex;

      &__title {
        margin-top: 0;
        margin-bottom: 4px !important;
      }
    }

    .admin-news__image {
      align-self: flex-start;
      margin-right: 12px;

      .app-mobile &,
      .app-tablet & {
        width: 128px;
        height: 90px;
        object-fit: cover;
      }

      .app-desktop & {
        width: 190px !important;
        margin-right: 20px;
        max-width: 40%;
      }

      @include m.respond-to(Lar) {
        width: 116px !important;
      }
    }
  }
}

.cards-row--wide {
  &:not(.preview-mode-tablet &):not(.preview-mode-mobile &) {
    .about-leader-news {
      .admin-news {
        flex-direction: row;

        &__tag-and-options {
          margin-top: 0;
        }

        &__image {
          width: 300px;
        }

        &__leader-body {
          flex: 1;
          margin-left: 16px;
          margin-top: 0;
        }

        &__title {
          margin-top: 0;
        }
      }
    }
  }
}
