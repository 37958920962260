@use './base/variables' as v;
@use './abstract/mixins' as m;

.top-banner-wrapper {
  width: 100%;
  color: v.$black-color;
  border-top: 1px solid v.$background-color-light;
  border-bottom: 1px solid v.$background-color-light;
  border-left: 1px solid v.$background-color-light;
  margin-bottom: 1px;

  .section-card__preview-mode & {
    border-left-width: 0;
    border-top-width: 0;
  }

  .owl-stage {
    display: flex;
    &:after {
      content: '';
    }

    .owl-item {
      display: flex;
    }
  }
}

.top-banner-slide {
  display: flex;
  width: 100%;
  position: relative;

  &:not(.top-banner-slide_preview) {
    @include m.respond-to(XMed) {
      flex-direction: column;
    }

    .section-card__preview-mode & {
      .app-tablet &,
      .app-mobile & {
        flex-direction: column;
      }
    }
  }

  &_main {
    .top-banner-slide__image {
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: -2px;
        z-index: 8;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          90deg,
          #ffffff 0%,
          rgba(255, 255, 255, 0.92) 1.41%,
          rgba(255, 255, 255, 0.85) 2.22%,
          rgba(255, 255, 255, 0) 10.39%
        );

        @include m.respond-to(XMed) {
          content: none;
        }

        .section-card__preview-mode & {
          .app-tablet &,
          .app-mobile & {
            content: none;
          }
        }
      }

      &.non-shadowed {
        &:before {
          content: none;
        }
      }
    }
    .section-card__preview-mode & {
      .app-tablet &,
      .app-mobile & {
        &:before {
          content: none;
        }
      }
    }
  }

  &_main,
  &_halfAndHalf {
    position: relative;

    &:before {
      content: '';
      display: block;
      padding-top: 28.14%;

      @include m.respond-to(XMed) {
        content: none;
      }

      .section-card__preview-mode & {
        .app-tablet &,
        .app-mobile & {
          content: none;
        }
      }
    }

    .top-banner-slide__image {
      width: 50%;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      display: flex;
      justify-content: flex-end;

      @include m.respond-to(XMed) {
        order: 1;
        position: relative;
        width: 100%;
        top: auto;
        left: auto;
        right: auto;
        bottom: auto;

        &:before {
          content: none;
        }
      }

      .section-card__preview-mode & {
        .app-tablet &,
        .app-mobile & {
          order: 1;
          position: relative;
          width: 100%;
          top: auto;
          left: auto;
          right: auto;
          bottom: auto;
        }
      }
    }
  }

  &_preview {
    .top-banner-slide {
      &__text {
        order: 1;
        width: 50%;
        padding: 96px 56px 80px 47px;

        .title {
          font-size: 36px;
        }
      }

      &__image {
        order: 2;
        width: 50%;

        .icon-image {
          width: 32px;
          height: 24px;
          margin-right: 8px;
        }
      }
    }
  }

  &_halfAndHalf {
    .section-card__preview-mode & {
      .app-tablet &,
      .app-mobile & {
        flex-direction: column;
      }
    }
  }

  &_circle {
    background: v.$black-color;

    .title,
    .description {
      color: v.$white-color;
    }

    .top-banner-slide__image {
      position: relative;
      width: 50%;

      &:before {
        content: '';
        display: block;
        padding-top: 56.25%;
      }

      @include m.respond-to(XMed) {
        width: 100%;
        order: 1;
      }

      .section-card__preview-mode & {
        .app-tablet &,
        .app-mobile & {
          width: 100%;
          order: 1;
        }
      }

      img {
        position: absolute;
        margin: auto;
        width: auto;
        height: 100%;
        inset: 0;
      }
    }
  }

  &_photo {
    flex-direction: column;
    background-size: cover;
    background-position: left center;

    &:before {
      content: '';
      display: block;
    }

    .section-card__preview-mode & {
      @include m.respond-to(XMed) {
        background: none !important;
      }

      .app-tablet &,
      .app-mobile & {
        background: none !important;
      }
    }

    .top-banner-slide__text {
      position: absolute;
      padding: 32px;
      background-color: v.$black-color;
      color: v.$white-color;
      top: 40px;
      left: 56px;
      right: 56px;
      width: calc(100% - 112px);

      @include m.respond-to(Lar) {
        padding: 24px;
      }

      @include m.respond-to(XMed) {
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        padding: 32px 56px 60px;
      }

      .section-card__preview-mode & {
        .app-tablet &,
        .app-mobile & {
          position: relative;
          top: 0;
          left: 0;
          right: 0;
          width: 100%;
        }
        .app-tablet & {
          padding: 32px 32px 60px;
        }

        .app-mobile & {
          padding: 60px 24px;
        }
      }

      &-inner {
        max-width: 960px;

        .section-card__preview-mode & {
          .app-tablet & {
            max-width: 647px;
          }
        }

        @include m.respond-to(XMed) {
          max-width: 647px;
        }
      }
    }

    .top-banner-slide__image {
      visibility: hidden;

      &:before {
        content: '';
        display: block;
        padding-top: 56.25%;
      }

      @include m.respond-to(XMed) {
        visibility: visible;
        width: 100%;

        &:before {
          content: none;
        }
      }
      .app-tablet &,
      .app-mobile & {
        visibility: visible;
        width: 100%;

        &:before {
          content: none;
        }
      }

      img {
        display: none;

        @include m.respond-to(XMed) {
          display: block;
        }
        .app-tablet &,
        .app-mobile & {
          display: block;
        }
      }
    }
  }

  &__text {
    padding: 40px 60px 40px 56px;
    width: 50%;

    .section-body__editing & {
      @include m.respond-from(XMed) {
        zoom: 0.78;
      }
    }

    .black-hah-theme & {
      background-color: v.$black-color;
    }

    .white-hah-theme & {
      background-color: v.$white-color;
    }

    .blue-hah-theme & {
      background-color: v.$light-blue-color;
    }

    .teal-hah-theme & {
      background-color: v.$accessible-teal;

      .top-banner-more-btn {
        @include m.link-styles-optional-color(v.$white-color);

        &-arrow {
          color: v.$white-color;
        }

        &:hover,
        &:active,
        &:focus {
          color: v.$white-color;

          .top-banner-more-btn-arrow {
            color: v.$white-color;
          }
        }
      }

      .top-banner-slide__button {
        @include m.bs-buttons-customize(
          v.$white-color,
          v.$accessible-teal,
          v.$white-color,
          v.$btn-outline-bg-hover,
          v.$white-color,
          v.$accessible-teal,
          v.$btn-outline-bg-hover,
          v.$white-color,
          v.$accessible-teal
        );
      }
    }

    .black-hah-theme &,
    .teal-hah-theme & {
      color: v.$white-color;
    }

    .section-card__preview-mode & {
      .app-tablet & {
        order: 2;
        width: 100%;
        padding: 32px 32px 60px;
        flex: 1;
      }

      .app-mobile & {
        order: 2;
        width: 100%;
        padding: 60px 24px;
        flex: 1;
      }
    }

    @include m.respond-to(XMed) {
      order: 2;
      width: 100%;
      padding: 32px 56px 60px;
      flex: 1;

      .navigation-enabled & {
        padding-bottom: 127px;
      }
    }

    @include m.respond-to(Med) {
      padding: 32px 32px 60px;
    }

    &-inner {
      .section-card__preview-mode & {
        .app-tablet & {
          max-width: 647px;
        }
      }

      @include m.respond-to(XMed) {
        max-width: 647px;
      }
    }

    .navigation-enabled & {
      .app-tablet & {
        max-width: 100%;
        padding: 32px 32px 60px;
      }

      .app-mobile & {
        max-width: 100%;
        padding: 60px 24px;
      }

      .section-card__preview-mode & {
        .app-tablet & {
          padding-bottom: 147px;
        }

        .app-mobile & {
          padding-bottom: 135px;
        }
      }
    }
  }

  &__image {
    width: 50%;

    p {
      font-weight: 600;
      font-size: 32px;
      line-height: 24px;
      color: v.$black-color;
    }
  }

  .title {
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 18px;

    @include m.respond-to(Lar) {
      font-size: 32px;
      line-height: 40px;
    }

    @include m.respond-to(XMed) {
      font-size: 24px;
      line-height: 40px;
    }

    .section-card__preview-mode & {
      .app-tablet &,
      .app-mobile & {
        font-size: 24px;
        line-height: 40px;
      }
    }
  }

  .description {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 24px;

    @include m.respond-to(XMed) {
      font-size: 14px;
      line-height: 20px;
    }

    .section-card__preview-mode & {
      .app-tablet &,
      .app-mobile & {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  .btn {
    font-size: 16px;
    padding: 9px 16px 11px;
    height: 42px;
  }

  .top-banner-more-btn {
    margin-top: auto;
    color: v.$about-gcu-blue;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    font-size: 16px;

    &:focus,
    &:hover,
    &:active {
      color: v.$about-gcu-blue;
      cursor: pointer;
      text-decoration: underline;

      .top-banner-more-btn-arrow {
        color: v.$about-gcu-blue;
      }
    }

    &-arrow {
      margin: 1px 0 0 5px;
      width: 16px;
      height: 16px;
      color: v.$about-gcu-blue;
    }

    span {
      display: inline-block;
      font-weight: 600;
    }
  }
}

.top-banner-navigation {
  position: absolute;
  z-index: 99;
  left: 56px;
  bottom: 40px;

  .section-body__editing & {
    @include m.respond-from(XMed) {
      left: 42px;
    }
  }

  @include m.respond-to(Hero1440) {
    left: 56px;
    bottom: 40px;
  }

  @include m.respond-to(XMed) {
    left: 56px;
    bottom: 60px;
  }

  .section-card__preview-mode & {
    .app-mobile & {
      left: 24px;
      bottom: 60px;
    }

    .app-tablet & {
      left: 32px;
      bottom: 60px;
    }
  }

  &_photo {
    background-color: v.$black-color;
    padding: 10px 0;
    bottom: 50px;

    @include m.respond-to(Hero1440) {
      bottom: 30px;
    }
    @include m.respond-to(XMed) {
      bottom: 50px;
    }

    .section-card__preview-mode & {
      .app-mobile &,
      .app-tablet & {
        bottom: 50px;
      }
    }
  }
}

.icon-image {
  width: 24px;
}

.fake-img {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: v.$background-color-light;
  width: 100%;
  height: 100%;

  .section-card__preview-mode & {
    .app-tablet &,
    .app-mobile & {
      min-height: 200px;
    }
  }
}
