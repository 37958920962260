@use 'base/variables' as v;

gridster {
  background: transparent !important;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .gridster {
    &-column {
      border-left: dashed 1px v.$light-grey-color-2 !important;
      border-right: dashed 1px v.$light-grey-color-2 !important;
    }

    &-row {
      border-bottom: dashed 1px v.$light-grey-color-2 !important;
      border-top: dashed 1px v.$light-grey-color-2 !important;
    }
  }
}

.gridster-item-resizable-handler.handle-se {
  right: 6px !important;
  bottom: 6px !important;
  width: 0 !important;
  height: 0 !important;
  border-style: solid !important;
  border-width: 0 0 24px 24px !important;
  border-color: transparent transparent v.$green-color transparent !important;
  opacity: 0 !important;
  visibility: hidden !important;
}

.gridster-preview {
  background: rgba(v.$blue-color, 0.2) !important;
  border: dashed 1px v.$btn-grey-color;
}

gridster-item {
  background: transparent !important;

  &:hover {
    .hover-state-enabled {
      box-shadow: 0 0 0 3px v.$green-color !important;
      .card-options {
        display: flex !important;
      }
    }
    .gridster-item-resizable-handler.handle-se {
      border-color: transparent transparent v.$green-color transparent !important;
      opacity: 1 !important;
      visibility: visible !important;
    }
  }

  app-card {
    height: 100%;
    width: 100%;
  }
}

.gridster-item-moving,
.gridster-item-resizing {
  z-index: 2 !important;
  box-shadow: none !important;
}
