@use 'variables' as v;
@use 'abstract/mixins' as m;

@include m.themed-section('white', v.$white-color, v.$black-color, v.$blue-color, v.$blue-color);
@include m.themed-section('black', v.$black-color, v.$white-color, v.$blue-color--on-black, v.$blue-color--on-black);
@include m.themed-section('teal', v.$blue-color, v.$white-color, v.$white-color, v.$white-color);
@include m.themed-section('blue', v.$light-blue-color, v.$black-color, v.$blue-color, v.$blue-color);
@include m.themed-section('light-blue', #f0f9fa, v.$black-color, #f0f9fa, #f0f9fa);
@include m.themed-section('gray', v.$gray-13, v.$black-color, v.$blue-color, v.$blue-color);
@include m.themed-section('gradient', v.$gradient-section, v.$white-color, v.$white-color, v.$white-color);
//@include m.themed-card('white-card', v.$white-color, v.$black-color, v.$blue-color, v.$blue-color, v.$black-color);
//@include m.themed-card('black-card', v.$black-color, v.$white-color, v.$blue-color--on-black, v.$blue-color--on-black, v.$white-color);

$themes-section: (
  'white': v.$white-color,
  'black': v.$black-color,
  'teal': v.$blue-color,
  'blue': v.$light-blue-color,
  'gray': v.$gray-13,
  'gradient': v.$gradient-section,
);
$themes-card: (
  'white': v.$white-color,
  'black': v.$black-color,
);

$multi-card-theme: ('white', 'blue', 'gray');

@mixin slider-navigation-styles($color) {
  .slider-navigation {
    color: $color;

    .slider-navigation__dots__dot {
      border-color: $color;

      &--active {
        background-color: $color;
      }
    }

    .slider-navigation__arrow {
      &:hover {
        color: $color;
      }
    }
  }
}

.multi-card {
  @each $name in $multi-card-theme {
    .#{$name} & {
      &.#{$name} {
        box-shadow: inset 0 0 0 1px v.$gray-18;
      }
    }
  }
}

.admin-card {
  @each $name, $color in $themes-card {
    .#{$name}-card & {
      background-color: $color;

      &.admin-card--transparent {
        background-color: transparent;
        color: $color;
      }

      @if ($name == 'white') {
        &.admin-card--transparent {
          color: $color;

          .admin-link,
          .uc-about-link,
          .lfh-help-list a {
            @include m.link-styles-optional-color(
              v.$accessible-teal,
              v.$accessible-teal--hover,
              v.$accessible-teal--active
            );

            &-empty {
              color: $color;
              text-decoration: none;

              &:focus,
              &:hover,
              &:active,
              &:visited {
                color: $color;
                text-decoration: none;
              }
            }
          }
        }

        color: v.$black-color;

        .admin-link,
        .uc-about-link,
        .lfh-help-list a {
          @include m.link-styles-optional-color(
            v.$accessible-teal,
            v.$accessible-teal--hover,
            v.$accessible-teal--active
          );
        }

        // specific card classes

        .promo-icon {
          color: v.$white-color !important;

          &-wrap {
            background-color: v.$black-color !important;
          }
        }

        @include slider-navigation-styles(v.$black-color);
      }

      @if ($name == 'black') {
        &.admin-card--transparent {
          color: $color;

          .admin-link,
          .uc-about-link,
          .lfh-help-list a {
            @include m.link-styles-optional-color(v.$black-bg-teal, v.$black-bg-teal--hover, v.$black-bg-teal--hover);

            &-empty {
              color: $color;
              text-decoration: none;

              &:focus,
              &:hover,
              &:active,
              &:visited {
                color: $color;
                text-decoration: none;
              }
            }
          }
        }

        color: v.$white-color;

        .admin-link,
        .uc-about-link,
        .lfh-help-list a {
          @include m.link-styles-optional-color(v.$black-bg-teal, v.$black-bg-teal--hover, v.$black-bg-teal--hover);
        }

        // specific card classes

        .promo-icon {
          color: v.$black-color !important;

          &-wrap {
            background-color: v.$white-color !important;
          }
        }

        .admin-news {
          &__info-option {
            background: #e6e6e6;
          }

          &__share-option {
            width: 14px;
            height: 14px;
            color: inherit;

            &--gray {
              color: #63666a;
            }
          }
        }

        @include slider-navigation-styles(v.$white-color);
      }
    }
  }
}

.section-body {
  @each $name, $color in $themes-section {
    &.#{$name} {
      @if ($name == 'white') {
        .white-card {
          &.about-promo,
          &.about-slider {
            margin-left: 2px; // fix slider shifting
            box-shadow: 0 0 0 1px v.$light-grey-color-2;
          }
        }
      }

      @if ($name == 'white' or $name == 'blue') {
        .admin-card--transparent .admin-link,
        .admin-card--transparent .uc-about-link,
        .admin-card--transparent .lfh-help-list a {
          @include m.link-styles-optional-color(
            v.$accessible-teal,
            v.$accessible-teal--hover,
            v.$accessible-teal--active
          );
        }
      }

      @if ($name == 'black') {
        .admin-card--transparent .admin-link,
        .admin-card--transparent .uc-about-link,
        .admin-card--transparent .lfh-help-list a {
          @include m.link-styles-optional-color(v.$black-bg-teal, v.$black-bg-teal--hover, v.$black-bg-teal--hover);
        }

        .admin-news {
          &__info-option {
            background: #333333;
          }
        }
      }

      @if ($name == 'teal' or $name == 'gradient') {
        .admin-card--transparent .admin-link,
        .admin-card--transparent .uc-about-link,
        .admin-card--transparent .lfh-help-list a,
        .admin-card--transparent .lfh-help-list span.uc-about-link {
          @include m.link-styles-optional-color(v.$white-color, v.$white-color, v.$white-color);

          &:hover {
            text-decoration: underline;
            cursor: pointer;
          }
        }

        .admin-news {
          &__leader-name {
            .admin-news__info-option {
              background: #4aa2ac;
            }

            &:hover {
              .admin-news {
                &__info-option {
                  background: v.$white-color;

                  app-icon {
                    color: v.$accessible-teal;
                  }
                }

                &__leader-name-text {
                  color: v.$white-color;
                }
              }
            }
          }

          &__tag {
            color: v.$accessible-teal;
          }
        }

        .admin-news__share-option {
          &:hover,
          &:active,
          &:focus,
          &:visited {
            color: v.$white-color;
          }
        }

        .admin-news .admin-news__share .show .admin-news__share-option {
          color: v.$white-color;
        }
      }
    }
  }

  .default-state {
    .section {
      &-title {
        color: v.$section-grey-title;
      }
    }
  }

  .default-state-link {
    color: v.$section-grey-title;
    pointer-events: none;

    &:hover,
    &:focus {
      color: v.$section-grey-title;
    }

    &__icon {
      fill: v.$section-grey-title;
    }
  }
}

//TODO: Replace to specific file
.about-slider,
.about-promo,
.about-gcu,
.about-vo-card {
  height: 100%;
}
