@font-face {font-family: "admin-icon";
  src: url('admin-icon.eot?t=1705947060229'); /* IE9*/
  src: url('admin-icon.eot?t=1705947060229#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url("admin-icon.woff2?t=1705947060229") format("woff2"),
  url("admin-icon.woff?t=1705947060229") format("woff"),
  url('admin-icon.ttf?t=1705947060229') format('truetype'), /* chrome, firefox, opera, Safari, Android, iOS 4.2+*/
  url('admin-icon.svg?t=1705947060229#admin-icon') format('svg'); /* iOS 4.1- */
}

[class^="admin-icon--"], [class*=" admin-icon--"] {
  font-family: 'admin-icon' !important;font-size: undefined;
  font-style:normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

$admin-icon--hamburger-menu: "\ea01";
$admin-icon--info-icon-circle: "\ea02";
$admin-icon--launchpad: "\ea03";
$admin-icon--news: "\ea04";
$admin-icon--tool-cards: "\ea05";

.admin-icon--hamburger-menu:before { content: $admin-icon--hamburger-menu; }
.admin-icon--info-icon-circle:before { content: $admin-icon--info-icon-circle; }
.admin-icon--launchpad:before { content: $admin-icon--launchpad; }
.admin-icon--news:before { content: $admin-icon--news; }
.admin-icon--tool-cards:before { content: $admin-icon--tool-cards; }

