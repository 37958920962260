@use 'variables' as v;

$modal-md: 600px;
$modal-content-border-radius: 0;
$btn-line-height: 1.428rem;
$btn-border-radius: 0;
$btn-border-radius-sm: 0;
$btn-border-radius-lg: 0;
$btn-font-size-sm: 1rem;
$input-btn-padding-y: 5px;
$input-btn-padding-x: 12px;
$input-border-radius: 0;
$form-text-color: v.$dark-grey-color;
$form-label-color: v.$dark-grey-color;
$form-label-font-size: 0.857rem;
$form-text-font-size: 1rem;
$form-label-margin-bottom: 0.417rem;
$form-text-margin-top: 0.5714rem;
$dropdown-border-radius: 0;
$dropdown-item-padding-y: 6px;
$dropdown-item-padding-x: 12px;

//$input-btn-focus-box-shadow: none;
//$btn-focus-box-shadow: none;

$theme-colors: (
  'primary': v.$green-color,
  'secondary': v.$grey-color,
  'blue': v.$blue-color,
);

$spacer: 1.142857rem;
