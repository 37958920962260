@use 'base/variables' as v;
@use 'abstract/mixins' as m;

.collaboration-central {
  padding: 24px 24px 32px;
  background: v.$preview-bg-color;
  font-weight: 600;

  .preview-mode-tablet & {
    padding: 24px 24px 32px;
  }

  .preview-mode-mobile & {
    padding: 24px 20px 32px;
  }

  &_headline {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 24px;

    .title {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 1px;
      color: v.$black-color;

      .preview-mode-tablet &,
      .preview-mode-mobile & {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 1px;
      }
    }

    .subtitle {
      color: v.$dark-grey-color;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    }

    .view-all-link {
      color: v.$blue-color;
      text-decoration: none;
      display: flex;
      align-items: center;
      white-space: nowrap;
      margin: 4px 0 0 8px;
      font-size: 14px;
      line-height: 18px;

      .icon {
        color: v.$blue-color;
        width: 16px;
        height: 16px;
        margin-left: 5px;
      }
    }
  }

  &_list {
    display: flex;
    justify-content: space-between;

    .preview-mode-tablet & {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 24px;
      justify-items: center;
    }

    .preview-mode-mobile & {
      flex-wrap: wrap;
      gap: 24px;
    }

    &-item {
      display: block;
      color: v.$black-color;
      text-decoration: none;
      width: 158px;
      text-align: center;

      .preview-mode-tablet & {
        width: 126px;

        &:nth-child(3n + 1) {
          justify-self: left;
        }

        &:nth-child(3n + 3) {
          justify-self: right;
        }
      }

      .preview-mode-mobile & {
        width: 126px;
      }

      &-icon-wrap {
        display: flex;
        justify-content: center;
      }

      &-icon-box {
        width: 96px;
        height: 96px;
        background: v.$white-color;
        border: 1px solid #e6e6e6;
        border-radius: 20px;
        padding: 12px;
        margin-bottom: 14px;
        display: flex;
        align-items: center;
        justify-content: center;

        @include m.respond-to(Med) {
          width: 80px;
          height: 80px;
        }

        .preview-mode-tablet &,
        .preview-mode-mobile & {
          width: 80px;
          height: 80px;
        }

        .tool-img {
          width: 100%;
        }
      }

      &-icon {
        width: 100%;
        height: 100%;
      }

      &-name {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;

        @include m.respond-to(Med) {
          font-size: 14px;
        }

        .preview-mode-tablet &,
        .preview-mode-mobile & {
          font-size: 14px;
        }
      }
    }
  }
}
