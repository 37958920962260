@use '../base/colors' as colors;

.dds-icon {
  &_teal {
    color: colors.$accessible-teal;
  }
  &_blue {
    color: colors.$accessible-blue;
  }
  &_green {
    color: colors.$accessible-green;
  }
}
