@import 'project/src/assets/fonts/admin/admin-icon';

.admin-icon {
  font-family: 'admin-icon' !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

span.admin-icon {
  font-family: 'admin-icon' !important;
}
