@use 'admin/base/colors' as colors;

.dds-button {
  &--teal {
    .dds-btn {
      background-color: colors.$accessible-teal;
      box-shadow: inset 0 0 0 1px colors.$accessible-teal;
      &:hover {
        background-color: colors.$accessible-teal-hover;
        box-shadow: inset 0 0 0 1px colors.$accessible-teal-hover;
      }

      &:active {
        background-color: colors.$accessible-teal-active;
        box-shadow: inset 0 0 0 1px colors.$accessible-teal-active;
      }
    }
  }
}
