.section-options {
  &__tooltip {
    &.show {
      opacity: 1;
    }
    .tooltip-arrow:before {
      border-top-color: #53565a;
    }

    .tooltip-inner {
      background: #53565a;
      border-radius: 0;
    }
  }
}
