@use 'variables' as v;
@use 'abstract/mixins' as m;

.input-control {
  &__title {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #53565a;
    margin-bottom: 5px;
  }

  &__field {
    box-sizing: border-box;
    width: 100%;
    height: 32px;
    padding: 6px 12px;
    border: 1px solid v.$input-border-color;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: v.$black-color;

    &::placeholder {
      color: v.$middle-grey-color;
    }
  }
}

.form-control {
  @include m.size-to-rem(line-height, 20);

  &.active:focus,
  &:active:focus,
  &:focus {
    box-shadow: none;
    border-color: v.$black-color;
  }

  &::placeholder {
    color: v.$middle-grey-color;
  }

  &.is-invalid {
    padding: 5px 12px;
    background: none; //hide bs validation icon

    &:focus {
      box-shadow: none;
    }
  }

  &-textarea {
    height: 124px;
    resize: none;
  }

  &-with-apply {
    position: relative;

    .form-control {
      padding-right: 36px;
    }

    &-button {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 4px;
      right: 4px;
      padding: 0;
    }

    &-icon {
      width: 14px;
      height: 14px;
    }
  }

  &-search {
    position: relative;

    .form-control {
      padding-left: 36px;
    }

    &-icon {
      position: absolute;
      top: 50%;
      left: 10px;
      width: 16px;
      height: 16px;
      transform: translateY(-50%);
    }
  }
}

.form-group {
  &_with-top-divider {
    padding-top: 12px;
    border-top: 1px solid v.$background-color-light;
  }
}

.form-text,
.form-label {
  @include m.size-to-rem(font-size, 12);
  @include m.size-to-rem(line-height, 16);

  &--large {
    @include m.size-to-rem(font-size, 14);
    @include m.size-to-rem(line-height, 20);
    font-weight: 600;
  }
}

.form-counter {
  margin-top: 0;
  margin-bottom: 5px;

  &.error {
    color: #dc3545;
  }
}

.form-label {
  &--tips {
    display: inline-flex;
    align-items: center;
  }

  &-icon {
    &-holder {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 16px;
      height: 20px;
      padding: 1px 0;
      cursor: pointer;
      background: v.$black-color;
      margin-left: 8px;
      color: v.$white-color;

      app-icon {
        width: 14px;
        height: 12px;
      }
    }
  }
}

.invalid-feedback {
  display: block;
  font-size: 12px;
  margin-top: 4px;
  margin-left: 2px;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  opacity: 1;
}

.custom-search {
  position: relative;
  display: flex;

  &__input {
    width: 100%;
    border: 1px solid v.$input-border-color;
    border-radius: 0;
    font-size: 14px;
    line-height: 20px;
    padding: 5px 12px 7px 36px;
    height: 32px;
    max-height: 32px;

    &::placeholder {
      color: v.$middle-grey-color;
    }

    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
      cursor: pointer;
      margin-left: 12px;
      width: 14px;
      height: 12px;
      background-image: url('data:image/svg+xml;utf8,<svg width="16" height="14" viewBox="0 0 16 14" fill="" xmlns="http://www.w3.org/2000/svg"><path d="M8 8.41406L13.5781 13.9922L15.0078 12.5859L9.40625 7.00781L15.0078 1.40625L13.5781 0L8 5.57812L2.42188 0L0.992188 1.40625L6.59375 7.00781L0.992188 12.5859L2.42188 13.9922L8 8.41406Z" fill="black"/></svg>');
      background-size: cover;
    }
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
  }
}

.card-x-position-group {
  display: grid;
  grid-template-columns: 80px 80px;
  gap: 8px;

  &__button {
    width: 80px;
    height: 68px;
    background-color: v.$gray-13;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 10px;
    line-height: 16px;
    color: v.$black-color;
    cursor: pointer;

    span {
      display: inline-block;
      margin-top: 6px;
    }

    input[type='radio'] {
      display: none;
    }

    &--checked {
      border: 1px solid v.$green-color;
    }
  }
}
