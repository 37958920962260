@use 'variables' as v;
@use 'abstract/mixins' as m;

.basic-btn {
  background-color: #ffffff;
  border: 1px solid #000000;
  padding: 10px 20px;
  border-radius: 4px;

  &_black {
    background-color: #000000;
    color: #ffffff;
  }

  &_grey {
    border: none;
    background-color: #f0f0f0;
    color: #000000;
  }

  &_teal-border {
    border: 1px solid #0d8390;
    background-color: #ffffff;
    color: #0d8390;
  }

  &_teal {
    border: none;
    background-color: #0d8390;
    color: #ffffff;
  }

  &_green {
    border: none;
    background-color: v.$green-color;
    color: #ffffff;
  }

  &_green-border {
    border: 1px solid v.$green-color;
    background-color: #ffffff;
    color: v.$green-color;
  }

  &_white-border {
    border: 1px solid v.$white-color;
    background-color: v.$green-color;
    color: v.$white-color;
    line-height: 20px;
    padding: 11px 16px;
  }
}

.filters-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding: 8px 16px;
  background-color: transparent;
  border: none;
  white-space: nowrap;
  cursor: pointer;

  &_green {
    color: v.$green-color;
  }

  &_red {
    color: v.$red-color;
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 8px;
  }
}

.admin-button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding: 8px 16px;
  min-width: 92px;
  background-color: transparent;
  border: none;
  color: v.$white-color;
  transition: all ease-in-out 0.2s;
  white-space: nowrap;

  &:hover {
    opacity: 0.8;
  }

  &:disabled {
    opacity: 0.5;

    &:hover {
      opacity: 0.5;
    }
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 14px;

    app-icon {
      width: 14px;
    }
  }

  &--outline-green {
    border: solid 1px v.$green-color;
    color: v.$green-color;
  }

  &--primary-green {
    background-color: v.$green-color;
  }

  &--primary-tile {
    background-color: v.$blue-color;
  }
}

.btn {
  padding: 9px 32px 9px 16px;
  margin: 0;
  height: 40px;

  &-sm {
    height: 32px;
    padding: 5px 24px 5px 12px;
  }

  &.active:focus,
  &:active:focus,
  &:focus {
    box-shadow: none;
  }

  &-primary {
    @include m.bs-buttons-customize(
      v.$green-color,
      v.$white-color,
      v.$green-color,
      v.$btn-primary-hover,
      v.$btn-primary-hover,
      v.$white-color,
      v.$btn-primary-focus,
      v.$btn-primary-focus,
      v.$white-color
    );
  }

  &-blue {
    @include m.bs-buttons-customize(
      v.$blue-color,
      v.$white-color,
      v.$blue-color,
      v.$accessible-teal--hover,
      v.$accessible-teal--hover,
      v.$white-color,
      v.$accessible-teal--active,
      v.$accessible-teal--active,
      v.$white-color
    );
  }

  &-outline-primary {
    @include m.bs-buttons-customize(
      v.$white-color,
      v.$green-color,
      v.$green-color,
      v.$btn-outline-bg-hover,
      v.$btn-outline-border-hover,
      v.$btn-outline-border-hover,
      v.$btn-outline-bg-focus,
      v.$btn-outline-border-focus,
      v.$btn-outline-border-focus
    );
  }

  &-outline-secondary {
    @include m.bs-buttons-customize(
      v.$green-color,
      v.$white-color,
      v.$white-color,
      v.$btn-primary-hover,
      v.$white-color,
      v.$white-color,
      v.$btn-primary-focus,
      v.$white-color,
      v.$white-color
    );
  }

  &-switch {
    @include m.bs-buttons-customize(v.$white-color, v.$black-color, v.$input-border-color);
    min-width: 139px;
    text-align: left;
  }

  &-dropdown {
    @include m.bs-buttons-customize(v.$white-color, v.$black-color, v.$input-border-color);
    padding: 9px 16px;

    @include m.ellipsis;

    &::after {
      display: none !important;
    }

    &--wide {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }

    &--link-url {
      padding-left: 41px;
      background-position: 14px center;
      background-repeat: no-repeat;
      background-size: 13px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11' height='8' viewBox='0 0 11 8' fill='none'%3E%3Cpath d='M2 0.375H4.625V2.125H2V5.625H4.625V7.375H0.25V0.375H2ZM10.75 6.5V7.375H6.375V5.625H9V2.125H6.375V0.375H10.75V6.5ZM7.25 3H3.75V4.75H7.25V3Z' fill='black'/%3E%3C/svg%3E");
    }
  }

  &-check {
    &:active + .btn-switch,
    &:checked + .btn-switch {
      @include m.bs-buttons-customize(v.$black-color, v.$white-color, v.$black-color);
    }

    &:focus {
      + .btn-switch {
        box-shadow: none;
      }
    }
  }

  &-link {
    @include m.bs-buttons-customize(transparent, v.$green-color, transparent);
    padding: 0;
    text-decoration: none;
  }

  &-link--white {
    @include m.bs-buttons-customize(transparent, v.$white-color, transparent);
    padding: 0;
    text-decoration: none;
  }

  &--order {
    display: flex;
    align-items: center;
    padding: 5px 10px 7px 12px;
    border: none;
    height: auto;
    position: relative;
    margin-left: 32px;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: -16px;
      height: 100%;
      width: 1px;
      background-color: v.$input-border-color;
    }

    span {
      display: inline-block;
      margin-right: 10px;
    }
  }
}
