.NgxEditor__Wrapper {
  border: 1px solid #ced4da !important;
  border-radius: 0 !important;

  &--is-invalid {
    border: 1px solid var(--bs-form-invalid-border-color) !important;
  }
}

.NgxEditor__Content {
  min-height: 124px;

  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }

  ul,
  ol {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 40px;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }
}

.NgxEditor__MenuItem {
  cursor: pointer;
}
