@use 'base/variables' as v;
@use 'abstract/mixins' as m;

.vo-card {
  padding: 32px;
  background: v.$about-vo-blue;
  color: v.$white-color;
  min-height: 248px;
  height: 100%;

  .preview-mode-mobile & {
    min-height: initial;
    height: auto;
    padding: 32px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    &-title {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;

      .preview-mode-mobile & {
        font-size: 18px;
        line-height: 24px;
      }
    }

    &-link {
      display: flex;
      align-items: center;
      text-decoration: none;
      white-space: nowrap;
      font-weight: 600;
      @include m.link-styles-optional-color(v.$white-color);

      &:focus,
      &:hover,
      &:active {
        text-decoration: none;
      }

      .preview-mode-mobile & {
        padding-top: 8px;
      }
    }
  }

  &__info {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin: 8px 0 24px;
  }

  &__section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    border: 1px solid v.$white-color;
    padding: 11px 16px;
    text-decoration: none;
    color: v.$white-color;
    flex: 1;

    &:hover,
    &:focus,
    &:active {
      color: v.$white-color;
    }

    &-wrapper {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      column-gap: 16px;

      .preview-mode-mobile & {
        flex-direction: column;
        row-gap: 16px;
      }

      .about-vo-small-view & {
        @include m.respond-from(Hero1200) {
          flex-direction: column;
          row-gap: 16px;
        }

        .preview-mode-mobile & {
          flex-direction: column;
        }

        .preview-mode-tablet & {
          flex-direction: row;
        }
      }
    }

    &-title {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    }

    &-icon {
      margin-left: 6px;
      width: 16px;
      height: 16px;
      min-width: 16px;
    }
  }

  &-empty {
    display: flex;
    min-height: 248px;
    height: 100%;
    padding: 0;
    background: v.$about-vo-blue;
    color: v.$white-color;

    .preview-mode-tablet & {
      min-height: 342px;
    }

    .preview-mode-mobile & {
      padding: 32px;
      min-height: initial;
      height: auto;
      flex-direction: column;
    }

    &__title {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 8px;
    }

    &__mobile-title {
      display: none;
      margin-bottom: 8px;
      .preview-mode-mobile & {
        display: flex;
      }
    }
    &__desktop-title {
      display: flex;
      .preview-mode-mobile & {
        display: none;
      }
    }

    &__vo-description {
      .preview-mode-mobile & {
        margin-top: 8px;
      }
    }

    &__iframe {
      position: relative;
      width: 442px;
      height: 100%;
      min-height: 248px;
      max-width: 55%;

      &:not(.about-vo-small-view &) {
        &:not(.preview-mode-mobile &) {
          width: 100%;
          max-width: 100%;
          height: 330px;
        }

        @include m.respond-to(Hero1200) {
          width: 442px;
          height: auto;
          max-width: 55%;

          .preview-mode-tablet & {
            width: 376px;
            min-height: initial;
            max-width: initial;
            height: auto;
          }

          .preview-mode-mobile & {
            width: 100%;
            height: auto;
            min-height: initial;
            max-width: initial;
          }
        }
      }

      .preview-mode-tablet & {
        width: 376px;
        min-height: initial;
        max-width: initial;
        height: auto;
      }

      .preview-mode-mobile & {
        width: 100%;
        height: auto;
        min-height: initial;
        max-width: initial;
      }

      &__overlay {
        position: absolute;
        cursor: pointer;
        z-index: 1;
        width: 100%;
        height: 100%;
        background: transparent;

        &__play_button {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 40px;
          height: 40px;
          background-color: v.$blue-color;

          app-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 26px;
            height: 26px;
          }
        }
      }

      &:after {
        content: '';
        position: absolute;
        background: url('../../images/vo-card-video-img.png');
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        background-size: cover;
        background-position: center;
        z-index: 0;
      }
    }

    &__container {
      display: flex;
      flex-direction: column;
      padding: 32px;
      flex: 1;
      justify-content: space-between;

      .preview-mode-tablet & {
        padding: 32px;

        &.no-video {
          padding: 0;
        }
      }

      .preview-mode-mobile & {
        padding: 0;
      }

      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: v.$white-color;
        margin-bottom: 24px;

        .preview-mode-tablet & {
          margin-bottom: 8px;
        }
      }

      a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        line-height: 22px;
        color: v.$accessible-teal;
        background: v.$white-color;
        padding: 9px 16px 11px 16px;
        min-height: 42px;
        border: none;
        text-decoration: none;
        align-self: flex-start;
        transition: 0.15s ease-in-out;

        &:hover,
        &:focus {
          color: v.$accessible-teal--hover;
          box-shadow: inset 0 0 0 1px v.$accessible-teal--hover;
          background-color: rgba(240, 249, 250, 0.94);
        }
      }
    }
  }
}
