.tooltip-modal-info {
  margin-left: -8px;

  .tooltip {
    &-arrow {
      margin-left: 11px;
    }

    &-inner {
      text-align: left;
      font-size: 1rem;
      max-width: 340px;
      padding: 9px 16px 11px;
      border-radius: 0; //TODO: set this in bs variables reset
    }
  }
}

.tooltip-page-list {
  margin-left: -8px;

  .tooltip {
    &-inner {
      text-align: center;
      font-size: 12px;
      line-height: 16px;
      max-width: 340px;
      padding: 4px 12px 6px;
      border-radius: 0;
    }
  }
}
