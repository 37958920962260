@use 'admin/functions/blend-transparent' as b;

// COLORS
// Covers
$cover_4: rgba(white, 0.04) !default;
$cover_black_4: rgba(black, 0.04) !default;
$cover_6: rgba(black, 0.06) !default;
$cover_8: rgba(black, 0.08) !default;
$cover_12: rgba(black, 0.12) !default;
$cover_16: rgba(black, 0.16) !default;
$cover_24: rgba(black, 0.24) !default;
$cover_32: rgba(black, 0.32) !default;
$cover_48: rgba(black, 0.48) !default;
//------------------------------------------------------------------------------------------------------------

// COLOR PALETTE

// Black
$black: #000000 !default;
// White
$white: #ffffff !default;
// Accessible Green
$accessible-green: #26890d !default;
$accessible-green_active: b.blend-transparent($cover_24, $accessible-green) !default;
// Accessible Blue
$accessible-blue: #007cb0 !default;
$accessible-blue_active: b.blend-transparent($cover_24, $accessible-blue) !default;
// Accessible Teal
$accessible-teal: #0d8390 !default;
$accessible-teal_active: b.blend-transparent($cover_24, $accessible-teal) !default;
$accessible-teal_hover: b.blend-transparent($cover_16, $accessible-teal) !default;

// Red
$red: #da291c !default;
// Light gray
$light-gray: #f5f5f5;
// Dark grey
$dark-gray: #0a0a0a;
// Cool Gray 2
$cool-gray-2: #d0d0ce !default;
// Cool Gray 4
$cool-gray-4: #bbbcbc !default;
// Cool Gray 6
$cool-gray-6: #a7a8aa !default;
// Cool Gray 7
$cool-gray-7: #97999b !default;
// Cool Gray 9
$cool-gray-9: #75787b !default;
// Cool Gray 10
$cool-gray-10: #63666a !default;
// Cool Gray 11
$cool-gray-11: #53565a !default;
// Cool Gray 12
$cool-gray-12: #f6f6f6 !default;
// Cool Gray 13
$cool-gray-13: #dddddd !default;
// Cool Gray 14
$cool-gray-14: #f8f8fa !default;
// Placeholder Gray
$placeholder-gray: #9e9e9e !default;
// Green 1
$green-1: #e3e48d !default;
// Green 2
$green-2: #c4d600 !default;
// Deloitte Green
$deloitte-green: #86bc25 !default;
// Green 4
$green-4: #43b02a !default;
// Green 5
$green-5: #009a44 !default;
// Green 6
$green-6: #046a38 !default;
// Green 7
$green-7: #2c5234 !default;
// Blue 1
$blue-1: #a0dcff !default;
// Blue 2
$blue-2: #62b5e5 !default;
// Blue 3
$blue-3: #00a3e0 !default;
// Blue 4
$blue-4: #0076a8 !default;
// Blue 5
$blue-5: #005587 !default;
// Blue 6
$blue-6: #012169 !default;
// Blue 7
$blue-7: #041e42 !default;
// Blue 8
$blue-8: #007cb0 !default;
// Teal 1
$teal-1: #ddefe8 !default;
// Teal 2
$teal-2: #9dd4cf !default;
// Teal 3
$teal-3: #6fc2b4 !default;
// Teal 4
$teal-4: #00abab !default;
// Teal 5
$teal-5: #0097a9 !default;
// Teal 6
$teal-6: #007680 !default;
// Teal 7
$teal-7: #004f59 !default;
// Orange
$orange: #ed8b00 !default;
// Yellow
$yellow: #ffcd00 !default;
// Bright Green
$bright-green: #0df200 !default;
// Bright Teal
$bright-teal: #3efac5 !default;
// Bright Blue
$bright-blue: #33f0ff !default;
// Middle Gray
$middle-gray: #e5e5e5;
// Highlight Green
$highlight-green: #e9f3e7;
// Accessible Green
$accessible-green: #26890d;
