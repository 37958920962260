$white-color: #ffffff;
$grey-color: #c9c9c9;
$background-color-light: #d9d9d9;
$light-grey-color-2: #d0d0ce;
$light-grey-color-3: #c4c4c4;
$light-grey-color-4: #f8f8f9;
$dark-grey-color: #53565a;
$middle-grey-color: #63666a;
$light-grey-color: #97999b;
$lighter-grey-color: #c9c9c9;
$card-type-color: #f3f3f3;
$card-background: #f6f8fc;
$card-border: #e6f2fe;
$card-drafted: #97999b;
$black-color: #000000;
$blue-color: #0d8390;
$blue-color--on-black: #00abab;
$light-blue-color: #edf7fa;
$grey-color: #f8f8f8;
$gray-12: #e1e1e1;
$gray-13: #f5f5f5;
$grey-dark-color: #a7a8aa;
$red-color: #da291c;
$input-border-color: #d0d0ce;
$input-grey-color: #75787b;
$btn-grey-color: #bbbcbc;
$disable-button: #97999b;
$error-color: #d20707;
$section-grey-bg: #ececec;
$section-grey-title: #9e9e9e;
$add-icon-border-color: #efefef;
$add-icon-btn-bg-color: #f7f7f7;
$preview-bg-color: #f6f6f6;
$green-color: #26890d;
$checkbox-color: #25890e;
$light-green-color: #26890d14;
$toastbg: #2e2e2e;
$about-vo-blue: #005587;
$about-vo-blue-hover: #004771;
$about-vo-blue-active: #004167;
$about-gcu-blue: #0d8390;
$light-blue-res: #f0f9fa;
$gradient-section:
  radial-gradient(
      54.77% 286.12% at 88.71% -23.93%,
      rgba(100, 141, 163, 0.3) 0%,
      rgba(46, 65, 75, 0.1375) 50.38%,
      rgba(0, 0, 0, 0) 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
  radial-gradient(
      56.52% 118.16% at 33.95% -13.66%,
      rgba(100, 141, 163, 0.7) 0%,
      rgba(46, 65, 75, 0.320833) 50.38%,
      rgba(0, 0, 0, 0) 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
  radial-gradient(
      51.34% 93.67% at 54.92% -11.43%,
      #17c989 0%,
      rgba(17, 103, 74, 0.630208) 43.87%,
      rgba(8, 50, 36, 0.308721) 72.5%,
      rgba(0, 0, 0, 0) 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
  #000000;
$accessible-teal: #0d8390;
$accessible-teal--hover: #0b6e79;
$accessible-teal--active: #0a646d;
$accessible-teal--visited: #095962;
$gray-18: #e6e6e6;
$gray-19: #e3e3e3;
$accessible-blue: #007cb0;
$active-drop-zone: #cfecf6;

$black-bg-teal: #00abab;
$black-bg-teal--hover: #019090;
//theme colors

// screen resolution
$breakpoint__desktop: 1280px;
$breakpoint__tablet: 768px;
$breakpoint__mobile: 375px;
$fixed-layout-max-template-width: 1328px;
$fixed-layout-max-hero-width: 1440px;
$fixed-width: 1328px;

//buttons-states
$btn-primary-hover: #20730c;
$btn-primary-focus: #1e680a;
$btn-blue-hover: #026894;
$btn-blue-hover: #035e86;
$btn-outline-border-hover: #2a691c;
$btn-outline-bg-hover: #ebebeb;
$btn-outline-border-focus: #154707;
$btn-outline-bg-focus: #e0e0e0;

//page-builder card-skeleton
$white-card-bg: #fbfbfb;
$white-card-placeholder: #eeeeee;
$grey-card-placeholder: #e8e8e8;
$teal-card-bg: #9ac9ce;
$teal-card-placeholder: #8dbdc2;
$light-blue-card-bg: #f5f8f9;
$light-blue-card-placeholder: #e8ecec;
$black-card-bg: #959595;
$black-card-placeholder: #888888;
$gradient-card-bg: #a0bab4;
$gradient-card-placeholder: #8d9896;
