@use 'base/variables' as v;
@use 'abstract/mixins' as m;

.gcu {
  background: v.$about-gcu-blue;
  min-height: 248px;
  height: 100%;
  display: flex;

  .preview-mode-tablet & {
    min-height: 342px;
  }

  .preview-mode-mobile & {
    min-height: initial;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 32px;
  }

  &__iframe {
    position: relative;
    width: 442px;
    height: 100%;
    min-height: 248px;
    max-width: 55%;

    .about-vo-small-view & {
      @include m.respond-to(Hero1200) {
        width: 442px;
        height: auto;
        max-width: 55%;
      }
    }

    &:not(.about-vo-small-view &) {
      &:not(.preview-mode-mobile &) {
        width: 100%;
        max-width: 100%;
        height: 330px;
      }

      @include m.respond-to(Hero1200) {
        width: 442px;
        height: auto;
        max-width: 55%;

        .preview-mode-tablet & {
          width: 376px;
          min-height: auto;
          height: auto;
        }

        .preview-mode-mobile & {
          width: 100%;
          height: 151px;
          min-height: initial;
          max-width: initial;
        }
      }
    }

    .preview-mode-tablet & {
      width: 376px;
      min-height: auto;
      height: auto;
    }

    .preview-mode-mobile & {
      width: 100%;
      height: 151px;
      min-height: initial;
      max-width: initial;
    }

    &:after {
      content: '';
      position: absolute;
      background-image: url('../../images/gcu-video-img.png');
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      background-size: cover;
      background-position: center;
      z-index: 0;
    }

    &__overlay {
      position: absolute;
      cursor: pointer;
      z-index: 1;
      width: 100%;
      height: 100%;
      background: transparent;

      &__play_button {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 40px;
        height: 40px;
        background-color: v.$blue-color;

        app-icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 26px;
          height: 26px;
          color: v.$white-color;
        }
      }
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 24px;
    color: v.$white-color;

    &-meet {
      display: flex;
      width: fit-content;
      align-items: flex-start;
      cursor: pointer;
      transition: 0.1s ease-in;
      padding: 10px 9px;
      font-size: 14px;
      line-height: 20px;
      color: v.$white-color;
      text-decoration: none;

      &:hover {
        background-color: v.$accessible-teal--hover;
        color: v.$white-color;
        text-decoration: none;
      }
      &:focus,
      &:active {
        color: v.$white-color;
        text-decoration: none;
      }
    }

    &-label {
      font-size: 14px;
      line-height: 20px;
    }

    &-title {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 8px;
      color: v.$white-color;

      &--mobile {
        display: none;
        margin-bottom: 8px;

        .app-mobile & {
          display: flex;
        }
      }

      &--desktop {
        display: flex;

        .app-mobile & {
          display: none;
        }
      }
    }

    h4 {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      margin-bottom: 12px;
    }

    app-icon {
      margin-right: 8px;
      width: 24px;
      min-width: 24px;
      height: 24px;
      margin-left: -4px;
      color: v.$white-color;
    }

    .preview-mode-tablet & {
      margin-bottom: 8px;
    }

    .preview-mode-mobile & {
      margin-top: 8px;
    }

    &__wrapper {
      display: flex;
      padding: 32px;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      flex: 1;

      .preview-mode-mobile & {
        padding: 0;
      }
    }
  }

  &__button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    cursor: pointer;
    font-size: 16px;
    line-height: 22px;
    color: v.$about-gcu-blue;
    background: v.$white-color;
    padding: 9px 16px 11px 16px;
    min-height: 42px;
    border: none;
    margin-top: 25px;
    text-decoration: none;
    transition: 0.15s ease-in-out;

    .preview-mode-tablet & {
      margin-top: 0;
    }

    .preview-mode-mobile & {
      margin-top: 0;
    }

    &:hover,
    &:focus {
      color: v.$accessible-teal--hover;
      box-shadow: inset 0 0 0 1px v.$accessible-teal--hover;
      background-color: rgba(240, 249, 250, 0.94);
    }
  }
}
