@use '../base/colors' as color;

header {
  .header {
    .dds-header__container {
      padding: 0 4px 0 0;
    }

    .dds-header__main,
    .dds-header__logo,
    .dds-header__project-name {
      height: 100%;
    }

    .dds-header__project-name {
      padding: 0 16px 0 0;
      margin-bottom: 0;

      &:before {
        margin: 0 16px 0 0;
      }
    }
  }

  .title {
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 18px;
    line-height: 20px;
    padding: 0 16px;
  }

  .dds-header__logo {
    max-width: none;
  }

  .alerts.dds-btn {
    color: color.$cool-gray-7;

    .dds-btn__content {
      min-width: 40px;
    }
  }

  .dds-header__profile:has(.profile) {
    margin-left: 4px;
  }
}
