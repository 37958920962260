@use 'base/variables' as v;
@use 'abstract/mixins' as m;

.secondary-flow-block {
  min-width: 366px;
  width: 366px;
  position: relative;
  z-index: 1;
  margin-left: 20px;
  min-height: 400px;

  &__pseudo-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;

    .app-tablet & {
      padding-top: 44px;
    }

    .app-mobile & {
      padding-top: 24px;
    }
  }
  .helper {
    margin: 0;
  }
}

.preview-mode-tablet {
  .section-body--mini-carousel--right {
    .secondary-flow-block {
      margin-top: 104px;
    }
    .secondary-flow-block__pseudo-wrapper {
      padding-top: 55px;
    }
  }
}

.preview-mode-mobile {
  .section-body--mini-carousel--right {
    .secondary-flow-block {
      margin-top: 84px;
    }
    .secondary-flow-block__pseudo-wrapper {
      padding-top: 35px;
    }
  }
}

.preview-mode-tablet,
.preview-mode-mobile {
  .secondary-flow-block {
    min-width: auto;
    width: 100%;
    margin: 36px 0 0;
    background-color: v.$light-blue-color;

    &__pseudo-wrapper {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        z-index: -1;
        background-color: v.$light-blue-color;
        left: -32px;
        right: -32px;
        bottom: 0;
        top: -24px;
        width: calc(100% + 64px);
        height: calc(100% + 104px);
      }
    }
  }
}

.preview-mode-mobile {
  .secondary-flow-block {
    &__pseudo-wrapper {
      &:after {
        left: -24px;
        right: -24px;
        top: -24px;
        bottom: -80px;
        width: calc(100% + 48px);
        height: calc(100% + 108px); // 60 + 48
      }
    }
  }
}
