@use 'base/variables' as v;

.job-opportunities {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-content: space-between;
  gap: 50px 20px;

  .section-card__preview-mode & {
    .app-tablet & {
      gap: 70px 20px;
    }
    .app-mobile & {
      gap: 54px 0;
      margin-top: 10px;
    }
  }

  .job-opportunity {
    flex: 0 0 32%;

    .section-card__preview-mode & {
      .app-tablet & {
        flex: 0 0 calc(50% - 10px);
      }

      .app-mobile & {
        flex: 0 0 100%;
      }
    }

    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    &-info {
      margin-bottom: 15px;
      font-size: 12px;
      line-height: 16px;

      a {
        font-weight: 600;
      }

      span {
        color: v.$dark-grey-color;
        margin-left: 8px;
      }
    }

    &-title a {
      font-weight: 600;
      line-height: 20px;
    }

    &-subtitle {
      margin-top: 5px;
      line-height: 20px;
    }
  }
}

.black,
.teal,
.gradient {
  .job-opportunities {
    .job-opportunity-info span {
      color: v.$light-grey-color-2;
    }
  }
}

.preview-mode-tablet {
  .job-opportunity {
    flex: 0 0 48%;
  }
}

.preview-mode-mobile {
  .job-opportunities {
    column-gap: 0;
  }

  .job-opportunity {
    flex: 0 0 100%;
  }
}
