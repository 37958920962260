@use 'base/variables' as v;
@use 'abstract/mixins' as m;

.alert-widget-section {
  .app-tablet &,
  .app-mobile & {
    .modal_close {
      display: none;
    }
  }

  h4 {
    font-size: 16px;
  }

  h3 {
    font-size: 32px;
    margin-bottom: 26px;
  }

  .alert-container {
    .alert-header-item {
      span {
        font-size: 13px;
      }
    }

    .alert-box-tablet {
      display: flex;
      height: 55px;
      align-items: center;

      .alert-text {
        font-size: 14px;
        width: 240px;
      }
    }

    .alert-box .alert-link {
      display: flex;
      align-items: center;
      text-decoration: none;
      font-weight: 400;

      svg-icon {
        margin-top: 4px;
      }
    }

    .alert-box {
      .alert-link {
        cursor: pointer;
      }
    }
  }
}

.uc-about-section {
  &.alert-widget-section {
    padding: 43px 48px;
  }

  .alert-container {
    .alert-box {
      padding: 6px 0;
    }
  }
}

.alert-icon,
.notification-icon {
  width: 14px !important;
  height: 14px !important;
  min-width: 14px;
}

.modal-close {
  position: absolute;
  width: 24px;
  height: 16px;
  top: 30px;
  right: 15px;

  &.icon-close-gray {
    width: 16px;
    height: 16px;
    color: v.$input-grey-color;
  }
}

.alert-text {
  line-height: 20px;
  margin-left: 6px;
  width: calc(100% - 10px);
}

.alert-count {
  font-size: 12px;
  line-height: 1;
  background-color: v.$light-grey-color-3;
  padding: 4px 5px;
  vertical-align: middle;
}

.critical-alert {
  color: v.$red-color;

  &[href*="https://"], &[href*="http://"], &[href]
  {
    &:hover {
      color: v.$accessible-teal;
      text-decoration: underline;
    }
  }

  &:hover {
    color: v.$red-color;
    text-decoration: none;
  }
}

.normal-alert {
  color: v.$black-color;

  &[href*="https://"], &[href*="http://"], &[href]
  {
    &:hover {
      color: v.$accessible-teal;
      text-decoration: underline;
    }
  }

  &:hover {
    color: v.$black-color;
    text-decoration: none;
  }
}
.textAlert {
  cursor: pointer;
}

.nonTextAlert {
  cursor: default;
}

@media (max-width: 1023px) {
  .uc-card-wrapper {
    .alert-widget-section {
      display: none;
    }
  }
}

.modal-alert-container {
  padding: 24px;
}

.alert-accordion {
  &-item {
    margin: 0 0 8px 0;
    width: 100%;
    padding: 0 0 8px 0;

    &.card {
      background-color: transparent;
      border-radius: 0;
      border: none;
      border-bottom: 1px solid v.$gray-12;
      height: auto;
    }

    .card-header {
      background-color: transparent;
      border-radius: 0;
      border: none;
      padding: 18px 0;
    }

    .card-body {
      padding: 0;
    }

    &__head {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
    }

    &__description {
      margin: 0 10px 24px 20px;

      p {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  &-head {
    flex: 1;

    &__info {
      display: inline-flex;
      align-items: center;
      min-width: 0;

      .alert-link {
        display: flex;
        align-items: center;
        font-weight: 400;
        text-decoration: none;

        svg-icon {
          margin-top: 4px;
        }
      }
    }

    &__img {
      height: 24px;
      width: 24px;
      min-width: 24px;
      margin-right: 24px;
    }

    &__title,
    &__subtitle {
      font-size: 20px;
      line-height: 26px;
    }

    &__title {
      font-weight: 600;
      line-height: 28px;
      margin-right: 24px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &__caret {
      width: 16px;
      height: 16px;
      min-width: 16px;
      margin-left: 16px;

      &.opened {
        transform: rotate(180deg);
      }
    }

    &__counter {
      display: inline-flex;
      align-items: center;

      &-img {
        width: 12px;
        height: 12px;
        min-width: 12px;
        margin-right: 8px;
      }

      &-text {
        font-size: 12px;
        line-height: 16px;
        font-weight: 600;
      }
    }
  }
}

.alert-body {
  .alert-accordion-item {
    &.card {
      padding: 0;
    }
  }
}

@media screen and (max-width: 767px) {
  .alert-container {
    .alert-text {
      width: 98%;
    }
  }
}
