.ck-balloon-panel {
  &::before {
    display: none !important;
  }
  .ck-icon {
    width: 24px !important;
    height: 24px !important;
  }
}

.ck-powered-by-balloon {
  display: none !important;
}
