@use 'abstract/mixins' as m;

.dt-newsletter {
  &-image {
    max-width: 100%;
  }
}

.dt-newsletter {
  display: flex;

  .preview-mode-mobile & {
    flex-direction: column;
  }

  &_headline {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    flex-wrap: wrap;
    gap: 12px;

    .app-mobile & {
      align-items: baseline;
    }

    .headline {
      &-title {
        font-weight: 600;
        font-size: 24px;
        line-height: 1.33334;
      }

      &-link {
        display: flex;
        align-items: center;
        text-decoration: none;
        font-weight: 600;
        @include m.learn-more-link-size;

        &-icon {
          margin-left: 2px;
        }
      }
    }
  }

  &-image {
    max-width: 375px;
    width: 100%;

    .preview-mode-tablet & {
      max-width: 275px;
    }

    .preview-mode-mobile & {
      max-width: 100%;
    }

    &-wrapper {
      margin-right: 20px;
      width: 375px;
      min-width: 375px;

      .preview-mode-tablet & {
        width: 275px;
        min-width: 275px;
      }

      .preview-mode-mobile & {
        margin-right: 0;
        width: 100%;
        min-width: 100%;
      }
    }
  }

  &-content {
    .preview-mode-mobile & {
      margin-top: 16px;
    }
  }

  &-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 8px;
  }

  &-description {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
}
