.c-modal {
  .modal-content {
    border: 0;
    border-radius: 0;
  }

  .modal-header {
    border: none;
    padding: 32px 32px 24px;

    &__icon {
      cursor: pointer;
      align-self: flex-start;

      &:hover {
        transform: scale(1.05);
        transition: scale 0.2s;
      }
    }

    &__text {
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      padding-right: 20px;
    }
  }

  .modal-body {
    padding: 0 32px 36px 32px;
  }

  .modal-footer {
    display: flex;
    padding: 0 32px 24px 32px;
    justify-content: flex-end;
    border: none;

    .admin-button {
      margin: 0;

      &:first-child {
        margin-right: 16px;
      }
    }
  }
}

.modal-confirm {
  &__title {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
}

.overlapped {
  opacity: 0;
}
