@use '../functions' as function;
@use '../base/colors' as colors;

.subheader {
  height: 52px !important;
  background: colors.$black;
  align-items: center;

  &__title {
    &-wrapper {
      width: 100%;
      padding: 8px 24px 16px 0;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 32px;
      overflow: hidden;
      max-width: 100%;
    }

    flex-shrink: 1;
    padding: 4px 44px 4px 24px;
    font: function.generateFont(20px, 32px, 600, 'Open Sans');
    color: colors.$white;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }

  &__actions {
    display: flex;
    gap: 32px;
    flex-shrink: 0;
  }

  &__role-select {
    position: relative;
    z-index: 10;
    width: 290px;
    height: 52px;
    flex-shrink: 0;
  }
}
