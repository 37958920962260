@use 'admin/base/colors' as colors;

.admin-form-footer {
  flex-shrink: 0;
  padding: 12px;
  position: fixed;
  bottom: 8px;
  z-index: 11;
  background: white;
  box-shadow:
    0px -2px 4px 0px colors.$cover_12,
    0px 1px 2px 0px colors.$cover_24;
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  opacity: 1;
  transition:
    opacity,
    bottom 0.2s ease-out;

  &--hidden {
    opacity: 0;
    bottom: -60px;
  }

  &--fullWidth {
    width: calc(100% - 306px);
  }
}
