@use 'abstract/mixins' as m;
@use 'base/variables' as v;

.about-dot {
  .dot-card {
    margin-top: 12px;

    &__body {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 32px;
      padding: 24px;
      background-color: v.$white-color;
      @include m.respond-to(XSm) {
        flex-direction: column;
        align-items: flex-start;
        padding: 12px 20px 20px 14px;
      }
    }

    &__img {
      --dot-img-size: 84px;

      margin-right: 12px;
      width: var(--dot-img-size);
      height: var(--dot-img-size);
      max-width: var(--dot-img-size);
      max-height: var(--dot-img-size);
      object-fit: cover;

      @include m.respond-to(XMed) {
        --dot-img-size: 64px;
      }

      @include m.respond-to(Sm) {
        --dot-img-size: 76px;
      }

      @include m.respond-to(XSm) {
        --dot-img-size: 54px;
        margin-bottom: 8px;
      }
    }

    &__title {
      margin-bottom: 20px;
      font-size: 24px;
      font-weight: 600;
      line-height: 56px;
      color: v.$dark-grey-color;

      @include m.respond-to(Lar) {
        font-size: 18px;
        line-height: 32px;
      }

      @include m.respond-to(Sm) {
        margin-bottom: 16px;
      }

      @include m.respond-to(XSm) {
        margin-bottom: 12px;
        font-size: 16px;
        line-height: 24px;
      }
    }

    &__description {
      margin-bottom: 12px;
      font-size: 16px;
      color: v.$black-color;
      line-height: normal;

      @include m.respond-to(Lar) {
        margin-bottom: 8px;
        font-size: 14px;
      }
    }
  }

  .dot-projects {
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;

      @include m.respond-to(XSm) {
        flex-direction: column;
        align-items: flex-start;
      }

      &__title {
        margin-right: 8px;
        font-size: 24px;
        font-weight: 600;
        color: v.$dark-grey-color;
        line-height: 33px;

        @include m.respond-to(Lar) {
          font-size: 18px;
          line-height: 25px;
        }

        @include m.respond-to(XSm) {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }

    &__list {
      display: grid;
      gap: 20px;
      grid-template-columns: 1fr 1fr 1fr;

      @include m.respond-to(Sm) {
        grid-template-columns: 1fr;
      }

      &__project {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        min-width: 0;
        padding: 24px;
        background-color: v.$white-color;
        text-decoration: none;
        cursor: pointer;
        transition: all ease-in-out 0.3s;

        &:hover {
          box-shadow:
            0 1.2px 3.6px 0 rgba(0, 0, 0, 0.11),
            0 6.4px 14.4px 0 rgba(0, 0, 0, 0.13);
        }

        &__title {
          margin-bottom: 16px;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          color: v.$black-color;

          @include m.respond-to(Lar) {
            font-size: 14px;
            line-height: 24px;
            margin-bottom: 10px;
          }

          @include m.respond-to(Sm) {
            line-height: 19px;
          }
        }

        &__description {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          margin-bottom: 12px;
          font-size: 14px;
          font-weight: 400;
          color: v.$dark-grey-color;
          line-height: 24px;

          @include m.respond-to(Lar) {
            -webkit-line-clamp: 3;
          }

          @include m.respond-to(Sm) {
            -webkit-line-clamp: 2;
          }

          @include m.respond-to(XSm) {
            -webkit-line-clamp: 3;
          }
        }

        &__roles {
          position: relative;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          font-weight: 600;
          color: v.$accessible-blue;
          max-width: 100%;
          min-width: 100%;

          .tooltip-target {
            position: relative;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;

            &__icon {
              width: 24px;
              height: 24px;
              color: v.$accessible-blue;
            }

            &:hover {
              @include m.respond-from(Med) {
                .roles-tooltip {
                  visibility: visible;
                  opacity: 1;
                }
              }
            }
          }

          &__icon {
            width: 24px;
            height: 24px;
            color: v.$dark-grey-color;
          }

          &__text {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            padding-left: 8px;
            color: inherit;
            font-size: 14px;
            line-height: 20px;
            overflow: hidden;

            @include m.respond-to(Sm) {
              -webkit-line-clamp: 1;
            }

            @include m.respond-to(XSm) {
              -webkit-line-clamp: 3;
            }
          }
        }
      }
    }

    &__empty-title {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      margin-bottom: 12px;
      font-size: 16px;
      font-weight: 600;
      color: v.$black-color;
      line-height: 100%;

      .preview-mode-mobile & {
        font-size: 14px;
      }
    }

    .roles-tooltip {
      position: absolute;
      left: -8px;
      bottom: calc(100% + 8px);
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      max-width: 340px;
      width: max-content;
      padding: 7px 12px 9px 8px;
      font-size: 12px;
      line-height: 16px;
      color: v.$white-color;
      background-color: v.$black-color;
      z-index: 2;
      transition: all ease-in-out 0.3s;
      visibility: hidden;
      opacity: 0;
      cursor: default;

      @include m.respond-to(Lar) {
        max-width: 260px;
      }

      @include m.respond-to(Sm) {
        max-width: 340px;
      }

      span {
        display: inline-block;
        padding-left: 6px;
        padding-top: 4px;
      }

      &::after {
        content: '';
        position: absolute;
        left: 16px;
        bottom: -4px;
        width: 8px;
        height: 8px;
        background-color: v.$black-color;
        transform: rotate(45deg);
      }

      &__icon {
        width: 24px;
        height: 24px;
      }
    }
  }

  .about-card-link {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-decoration: none;
    white-space: nowrap;
    color: v.$blue-color;
  }

  .about-card-link-icon {
    width: 16px;
    height: 16px;
    vertical-align: top;
  }
}

.preview-mode {
  .about-dot {
    .dot-card {
      .app-mobile & {
        margin-top: 0;
      }

      &__title {
        .app-tablet & {
          font-size: 18px;
          line-height: 24px;
        }

        .app-mobile & {
          font-size: 16px;
          line-height: 24px;
        }
      }

      &__body__text__wrapper {
        .app-mobile & {
          flex: 1 0 100%;
          margin-top: 8px;
        }
      }

      &__description {
        .app-tablet & {
          font-size: 14px;
          line-height: normal;
        }
      }

      &__body {
        .app-mobile & {
          flex-wrap: wrap;
        }
      }
    }

    .dot-projects {
      &__list {
        .app-mobile &,
        .app-tablet & {
          grid-template-columns: 1fr;
        }

        &__project {
          &__title {
            .app-mobile &,
            .app-tablet & {
              font-size: 14px;
              line-height: normal;
            }

            .app-mobile & {
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
          }

          &__roles__text {
            .app-mobile & {
              -webkit-line-clamp: 3;
            }
          }
        }
      }

      &__header {
        .app-mobile & {
          flex-wrap: wrap;
        }

        &__title {
          .app-tablet & {
            font-size: 18px;
            line-height: 24px;
          }

          .app-mobile & {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }

    .about-card-link {
      .app-mobile & {
        flex: 1 0 100%;
        margin-top: 8px;
      }
    }

    .tooltip-target {
      &:hover {
        .roles-tooltip {
          .app-mobile &,
          .app-tablet & {
            visibility: hidden;
            opacity: 0;
          }
        }
      }
    }
  }
}
