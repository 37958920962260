@use 'base/variables' as v;
@use './abstract/mixins' as m;

.promo {
  &-wrapper {
    min-height: 400px;
    padding: 32px;
    display: flex;
    flex-direction: column;
    height: 100%;

    &.black {
      background: v.$black-color;
    }

    &.white {
      background: v.$white-color;
    }
  }

  &-info {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  &-title {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    margin: 20px 0 8px;
  }

  &-description {
    font-size: 14px;
    line-height: 20px;
    padding-bottom: 40px;
    margin-bottom: 8px;
  }

  &-more-btn {
    margin-top: auto;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    font-weight: 600;
    @include m.learn-more-link-size;

    &-arrow {
      width: 16px;
      height: 16px;
      margin-left: 5px;
    }
  }

  &-img {
    &-wrap {
      img {
        width: 100%;
        height: auto;
      }
    }
  }

  &-icon {
    color: v.$white-color;
    width: 24px !important;
    height: 24px !important;

    &-wrap {
      display: flex;
      width: 68px;
      height: 68px;
      min-height: 68px;
      background: v.$black-color;
      align-items: center;
      justify-content: center;
      margin-bottom: 16px;
    }
  }
}

.cards-row--wide {
  &:not(.preview-mode-tablet &):not(.preview-mode-mobile &):not(.section-body--mini-carousel &) {
    .promo {
      &-wrapper {
        min-height: 200px;
        flex-direction: row;
        height: 100%;
      }

      &-info {
        flex: 1;
        margin-left: 16px;
      }

      &-title {
        margin: 0 0 8px;
      }

      &-img {
        &-wrap {
          min-width: 268px;
          width: 268px;
          img {
            max-width: 100%;
          }
        }
      }

      &-icon {
        &-wrap {
          min-width: 68px;
        }
      }
    }
  }
}
