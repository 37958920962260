@use 'abstract/mixins' as m;

.welcome-card {
  background: transparent;
  padding: 16px 0;
  display: flex;

  .app-mobile & {
    flex-direction: column;
    padding: 0;
  }

  &-img {
    margin-right: 20px;

    .welcome-img {
      width: 100px;
      height: 100px;
    }
  }

  &-content {
    margin: 14px 0;
    flex-grow: 1;

    .app-mobile & {
      margin: 12px 0 0;
    }
  }

  &-title {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 4px;

    .app-mobile & {
      margin-bottom: 8px;
    }
  }

  &-description {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 4px;

    .app-mobile & {
      margin-bottom: 8px;
    }
  }

  &-close-icon {
    margin-top: 8px;

    .icon {
      width: 24px;
      height: 24px;
    }

    .app-mobile & {
      position: absolute;
      right: 0;
      top: 0;
      margin-top: 0;
    }
  }

  &-more-btn {
    margin-top: auto;
    color: #0d8390;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    font-weight: 600;
    @include m.learn-more-link-size;

    &-arrow {
      width: 16px;
      height: 16px;
      color: #0d8390;
      line-height: 20px;
    }
  }
}

.preview-mode {
  .section-body--welcome {
    padding: 0 56px !important;

    @include m.respond-to(Lar) {
      padding: 0 56px !important;
    }

    .app-tablet & {
      padding: 0 32px !important;
    }

    .app-mobile & {
      padding: 60px 24px !important;
    }
  }
}
