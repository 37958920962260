@use 'base/variables' as v;

.notification-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;

  &__body {
    display: flex;
    align-items: center;
  }

  &__content {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: v.$white-color;
  }

  &__icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }

  .alert-success {
    background: v.$black-color;
  }

  .alert {
    min-height: 35px;
    border-radius: 32px;
    margin-bottom: 0;
    padding: 8px 25px 8px 10px;
  }
}
