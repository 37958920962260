@use 'base/variables' as v;
@use 'abstract/mixins' as m;

.admin-header {
  position: relative;
  color: v.$black-color;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  
  .app-mobile &,
  .app-tablet & {
    margin-bottom: 48px;
    padding-left: 12px;
    border-bottom: 1px solid v.$btn-grey-color;
  }
}

.header-container {
  .app-desktop & {
    padding: 0 17px 0 29px;

    @media only screen and (min-width: m.$custom-hero-1440) {
      margin: 0 auto;
      max-width: v.$fixed-width;
      padding:0;
    }
  }
}

.header-mobile-trigger {
  display: none;
  position: absolute;
  right: 12px;
  top: 12px;
  width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;
  background: #000;
  color: #fff;
}

.header-mobile-trigger .user__ava,
.header-mobile-trigger img {
  height: 100%;
  line-height: 32px;
  cursor: pointer;
  width: 32px;
}

.header__logo {
  display: inline-block;
  vertical-align: middle;
  color: v.$black-color;
  text-decoration: none;
  flex: 0;
}

.header__logo svg-icon {
  width: 86px;
  height: 24px;
}

.header__logo span,
.header__logo svg-icon {
  display: inline-block;
  vertical-align: middle;
}

.header__logo span {
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  margin-top: -3px;
  white-space: nowrap;
}

.app-mobile .header__logo span {
  letter-spacing: 0.15px;
  margin-top: auto;
}

.app-mobile .header__logo span,
.app-tablet .header__logo span {
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
}

.header__left-part {
  height: 100%;
}

.header__left-part,
.header__right-part {
  align-items: center;
  display: flex;
}

.header__right-part {
  .app-tablet & {
    padding-left: 12px;
  }
}

.header__search {
  padding: 8px 0;
  width: 300px;
  margin-right: 21px;
}

.header__menu {
  position: relative;
  margin-left: 10px;

  &.header__menu--mock {
    .app-tablet & {
      margin-right: 6px;
    }

    .app-mobile & {
      position: absolute;
      right: 12px;
      top: 8px;
    }
  }

  &.open {
    app-icon.user__caret {
      transform: rotate(180deg);
    }
  }
}

.app-tablet .header__menu {
  margin-left: 11px;
  display: flex;
  align-items: center;
}

.header__menu_mobile {
  display: none;
  margin: 0;
}

.header__menu-trigger {
  width: 32px;
  height: 32px;
  text-align: center;
  cursor: pointer;
  background-color: #000;
  color: #fff;
  vertical-align: middle;
}

.header-top-divider {
  width: 1px;
  height: 20px;
  background-color: v.$light-grey-color-2;
  margin: 0 12px 0 16px;
}

.app-mobile .header-top-divider,
.app-tablet .header-top-divider {
  display: none;
}

.header__hamburger-btn {
  width: 56px;
  height: 100%;
  background: #0d8390;
  position: absolute;
  top: 0;
  left: 0;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.app-mobile .header__hamburger-btn {
  width: 54px;
}

.header__hamburger-icon {
  width: 18px;
  height: 18px;
}

.header__hamburger-icon.icon-burger {
  width: 24px;
  height: 24px;
  vertical-align: middle;
}

.header__waffle-menu {
  display: flex;
  align-items: center;
  margin-left: 4px;
  position: relative;
  width: 46px;
  justify-content: center;

  .app-tablet & {
    margin-left: 0;
  }
}

.app-mobile .admin-header {
  flex-direction: column;
  z-index: 1051;
  align-items: flex-start;
}

.app-mobile .header-mobile-trigger {
  display: flex;
}

.app-mobile .header-mobile-trigger {
  display: flex;

  .header__menu {
    display: block;
    margin: 0;
  }
}

.app-mobile .header__logo img {
  display: none;
}

.app-mobile .header__search {
  width: 100%;
  margin: 0;
}

.app-mobile .header__menu_mobile .user__menu {
  display: block;
  position: static;
  width: 100%;
  background: transparent;
  border: none;
}

.app-mobile .header__menu_mobile .user__menu li a {
  color: #fff;
}

.app-mobile .header__menu_mobile .user__menu li a:hover {
  background: hsla(0, 0%, 100%, 0.16);
}

.app-mobile .header__menu_mobile .user__ava {
  background: #75787b;
}

.app-mobile .header__menu_mobile .user__name {
  color: #fff;
}

.app-mobile .header__menu_mobile .user_lg {
  box-shadow: inset 0 -1px 0 #bbbcbc;
}

.app-mobile .header__waffle-menu {
  position: absolute;
  right: 54px;
  top: 12px;
}

.app-mobile .instant-search__bar-holder {
  position: absolute;
  right: 30px;
  top: 0;
}

.user {
  position: relative;
}

.user__ava {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: #53565a;
  color: #fff;
  border-radius: 50%;
  overflow: hidden;

  .header__menu--mock & {
    background: #000;
    border-radius: 50%;
  }
}

.user__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover !important;
  max-width: 100%;
  max-height: 100%;
}

.user__short-name {
  color: #fff;
  line-height: 28px;
  text-align: center;
}

.user__info {
  position: absolute;
  top: 11px;
  left: 65px;
  right: 15px;
}

.user__name,
.user__position {
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  color: #000;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.user__position {
  color: #75787b;
}

.user__caret {
  margin-left: 8px;
  width: 10px !important;
  height: 8px !important;

  &--gray {
    color: v.$light-grey-color-2 !important;
  }

  .app-mobile &,
  .app-tablet & {
    display: none;
  }
}

.user__menu {
  display: none;
  position: absolute;
  background: #fff;
  width: 240px;
  right: 20px !important;
  left: auto !important;
  border: 1px solid #bbbcbc;
  top: 50px !important;
  padding-bottom: 8px;
}

.user__menu li a,
.user__menu li button {
  display: block;
  padding: 10px 15px;
  font-size: 14px;
  line-height: 20px;
  color: #000;
  text-decoration: none;
}

.user__menu li button {
  border: none;
  background-color: transparent;
  width: 100%;
  display: flex;
  align-items: center;
}

.user__menu li a:hover,
.user__menu li button:hover {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)), #fff;
}

.user_toggle {
  cursor: pointer;
  white-space: nowrap;
}

.user_lg {
  padding: 15px;
  box-shadow: inset 0 -1px 0 #bbbcbc;
  position: relative;
  margin-bottom: 8px;
}

.user_lg .user__ava {
  width: 32px;
  height: 32px;
  line-height: 32px;
}

app-dt-header {
  .header__waffle-menu {
    width: 40px;
    margin-left: 0;
    padding: 16px 8px;

    .app-mobile & {
      width: 42px;
      top: 0;
      right: 64px;
    }
  }

  .instant-search__bar-holder {
    width: 40px;
    padding: 16px 8px;

    .app-mobile & {
      min-width: 42px;
    }
  }

  .unity-alert {
    .app-mobile & {
      min-width: 42px;
    }

    .app-tablet & {
      margin-left: 0;
    }
  }
}



#instantSearchOuterWrap {
  align-self: center;
  line-height: 1;
}

@media screen and (max-width: 1100px) and (min-width: 1024px) {
  .header-top-divider,
  .header__greeting--desktop {
    display: none;
  }

  .header__logo span {
    white-space: nowrap;
  }
}

@media screen and (max-width: 1023px) {
  #instantSearchOuterWrap {
    display: none;
  }
}

@media screen and (min-width: 1024px) {
  .desktop-hidden {
    display: none;
  }

  input.instant-search__input {
    width: 292px;
  }
}

@media screen and (max-width: 840px) and (min-width: 680px) {
  .header-top-divider,
  .header__greeting--desktop {
    display: none;
  }
}

.admin-header .uc-link {
  font-size: 12px;
  line-height: 16px;
  text-decoration: underline;
  color: #0d8390;
}

.admin-header .uc-link--no-under {
  text-decoration: none;
}

.admin-header .uc-link--ellipsed {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.admin-header .uc-link--black-teal {
  color: #000;
}

.instant-search__bar-trigger {
  width: 24px;
  height: 24px;
  color: #000;

  .app-mobile & {
    position: absolute;
    top: 16px;
    right: 130px;
  }
}

.dws-top-links {
  display: flex;
  white-space: nowrap;
  min-height: 56px;

  @media screen and (max-width: 1200px) and (min-width: 1024px) {
    padding: 0 0 0 24px;
  }

  .app-desktop-wide & {
    padding: 0 50px;
  }

  .app-tablet &,
  .app-mobile & {
    position: absolute;
    top: 56px;
    height: 48px;
    left: 0;
    right: 0;
    background-color: v.$white-color;
    padding: 0;
    box-shadow: inset 0 -1px 0 v.$btn-grey-color;
  }

  .app-tablet & {
    top: 56px;
    height: 48px;
    min-height: 48px;
  }

  .app-mobile & {
    min-height: 48px;
  }
}

.dws-top-link {
  cursor: pointer;
  color: v.$black-color;
  font-weight: 600;
  line-height: 24px;
  font-size: 14px;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 12px;
  text-decoration: none;

  &-arrow-right {
    color: v.$black-color;
    line-height: 24px;
    display: flex;
    align-items: center;
    position: relative;
    padding: 0 8px;
    background-color: #e2f0f2;
    display: none;

    .app-mobile & {
      display: flex;
      margin-left: 7px;
    }

    .dws-dd-arrow-right {
      display: flex;
    }
  }

  .dws-dd-arrow {
    width: 10px;
    height: 10px;
    margin: 6px 0 0 6px;
    padding: 2px;
  }
}

.dws-top-link__content {
  display: flex;
}

.NotMobile {
  .app-mobile & {
    display: none;
  }
}

.waffle-menu {
  position: relative;

  &__app-launch-icon {
    display: block;
    width: 16px;
    height: 16px;
    color: #000;
  }

  &__icons {
    background-color: transparent;
    color: #000;
    width: 32px;
    height: 32px;
    text-align: center;
    border-radius: 50%;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1.5px solid #000;
  }
}

.user_toggle {
  cursor: pointer;
  white-space: nowrap;
}

.user {
  position: relative;

  &__caret {
    display: inline-block !important;

    .header__menu--mock & {
      .app-mobile &,
      .app-tablet & {
        display: none !important;
      }
    }
  }
}

.unity-alert {
  display: block;
  min-width: 46px;

  .app-tablet & {
    display: block;
    margin-left: 8px;
  }

  .app-mobile & {
    display: flex;
    position: absolute;
    right: 106px;
    top: 0;
  }

  &-holder {
    cursor: pointer;
    height: 56px;
    width: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .app-mobile & {
      width: 42px;
    }
  }

  &-counter {
    display: block;
    position: absolute;
    right: 5px;
    top: 8px;
    background-color: v.$red-color;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    padding: 0 4px;
    color: v.$white-color;
  }
}
