@use 'base/variables' as v;
@use '../abstract/mixins' as m;

.pagination {
  position: relative;
  z-index: 1;
  .page-item {
    width: 40px;
  }

  .page-item .page-link,
  .pagination-page-link {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    padding: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: v.$black-color;
    border-radius: 4px;
    border: none;

    &:focus {
      box-shadow: none;
      background: transparent;
    }

    &:hover {
      background: none;
    }
  }

  .page-item.active .page-link {
    background: v.$light-grey-color-2;
  }

  .pagination-prev {
    width: auto;
    padding-right: 16px;
    margin-right: 16px;
    border-right: 1px solid v.$light-grey-color-2;

    .pagination-icon-arrows {
      margin-right: 10px;
    }
  }

  .pagination-next {
    width: auto;
    padding-left: 16px;
    margin-left: 16px;
    border-left: 1px solid v.$light-grey-color-2;

    .pagination-icon-arrows {
      margin-left: 10px;
    }
  }

  .pagination-icon-arrows {
    width: 14px;
    height: 20px;
  }

  .pagination-disable {
    color: v.$disable-button;
  }
}
