@use 'base/variables' as v;
@use 'abstract/mixins' as m;

.slider-navigation {
  display: flex;
  align-items: center;

  &__arrow {
    color: inherit;
    height: 16px;
    border: none;
    padding: 0;

    &:active,
    &:focus,
    &:disabled {
      border: none;
      box-shadow: none;
    }

    &:disabled {
      opacity: 0.5;
    }

    app-icon {
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
    }
  }

  &__dots {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    padding: 0 12px;

    &__dot {
      display: inline-block;
      width: 8px;
      height: 8px;
      margin: 0 12px;
      border: solid 2px v.$black-color;
      background-color: transparent;
      transition: background-color ease-in-out 0.25s;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }

      &--active {
        background-color: v.$black-color;
      }
    }
  }

  &--teal {
    color: v.$blue-color;

    .slider-navigation__dots__dot {
      border-color: v.$blue-color;

      &--active {
        background-color: v.$blue-color;
      }
    }
  }

  &--white {
    color: v.$white-color;

    .slider-navigation__dots__dot {
      border-color: v.$white-color;

      &--active {
        background-color: v.$white-color;
      }
    }

    .slider-navigation__arrow {
      &:hover {
        color: v.$white-color;
      }
    }
  }

  .blue-hah-theme &,
  .white-hah-theme & {
    color: v.$black-color;

    .slider-navigation__dots__dot {
      border-color: v.$black-color;

      &--active {
        background-color: v.$black-color;
      }
    }

    .slider-navigation__arrow {
      &:hover {
        color: v.$black-color;
      }
    }
  }

  .preview-mode-mobile & {
    .slider-navigation {
      justify-content: center;

      &__dots {
        padding: 0 6px;
      }
    }
  }
}

.rotate-180 {
  transform: rotate(180deg);
}
