@use 'base/variables' as v;

// don't use these variable into components
$xs: 375px;
$s: 768px;
$m: 1024px;
$xm: 1279px;
$l: 1366px;
$custom-hero-1200: 1200px;
$custom-hero-1440: 1440px;
$custom-section-divider: 1799px;
$xl: 1921px;
$xxl: 2560px;

@mixin respond-to($media) {
  @if $media == XSm {
    @media only screen and (max-width: $xs) {
      @content;
    }
  } @else if $media == Sm {
    @media only screen and (max-width: $s) {
      @content;
    }
  } @else if $media == Med {
    @media only screen and (max-width: $m) {
      @content;
    }
  } @else if $media == XMed {
    @media only screen and (max-width: $xm) {
      @content;
    }
  } @else if $media == Lar {
    @media only screen and (max-width: $l) {
      @content;
    }
  } @else if $media == Hero1200 {
    @media only screen and (max-width: $custom-hero-1200) {
      @content;
    }
  } @else if $media == Hero1440 {
    @media only screen and (max-width: $custom-hero-1440) {
      @content;
    }
  } @else if $media == XLar {
    @media only screen and (max-width: $xl) {
      @content;
    }
  } @else if $media == XXLar {
    @media only screen and (max-width: $xxl) {
      @content;
    }
  } @else {
    @media only screen and (min-width: $media) {
      @content;
    }
  }
}

@mixin respond-from($media) {
  @if $media == XSm {
    @media only screen and (min-width: $xs) {
      @content;
    }
  } @else if $media == Sm {
    @media only screen and (min-width: $s) {
      @content;
    }
  } @else if $media == Med {
    @media only screen and (min-width: $m) {
      @content;
    }
  } @else if $media == XMed {
    @media only screen and (min-width: $xm) {
      @content;
    }
  } @else if $media == Lar {
    @media only screen and (min-width: $l) {
      @content;
    }
  } @else if $media == Hero1200 {
    @media only screen and (min-width: $custom-hero-1200) {
      @content;
    }
  } @else if $media == Hero1440 {
    @media only screen and (min-width: $custom-hero-1440) {
      @content;
    }
  } @else if $media == XLar {
    @media only screen and (min-width: $xl) {
      @content;
    }
  } @else if $media == XXLar {
    @media only screen and (min-width: $xxl) {
      @content;
    }
  } @else {
    @media only screen and (min-width: $media) {
      @content;
    }
  }
}

@mixin icon-fill($fill-color, $fill-hover-color) {
  .icon-fill {
    fill: $fill-color;
  }

  &:hover {
    .icon-fill {
      fill: $fill-hover-color;
    }
  }
}

@mixin size-to-rem($property, $value) {
  #{$property}: calc($value / 14) + rem;
}

@mixin ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin ellipsis-multiline($height, $lines) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: $height;
  word-break: break-word;
  overflow-wrap: break-word;
}

@mixin icon-sizes($valueW, $valueH: null) {
  width: $valueW + px;

  @if ($valueH != null) {
    height: $valueH + px;
  } @else {
    height: $valueW + px;
  }
}

@mixin bs-buttons-customize(
  $bgcolor,
  $color,
  $border-color,
  $hover-bg: null,
  $hover-border: null,
  $hover-color: null,
  $focus-bg: null,
  $focus-border: null,
  $focus-color: null
) {
  color: $color;
  background-color: $bgcolor;
  border-color: $border-color;

  &.disabled,
  &:disabled,
  &:hover {
    color: $color;
  }
  @if (
    $hover-bg !=
      null and
      $hover-border !=
      null and
      $focus-bg !=
      null and
      $focus-border !=
      null and
      $hover-color !=
      null and
      $focus-color !=
      null
  ) {
    &:hover {
      background-color: $hover-bg;
      border-color: $hover-border;
      color: $hover-color;
    }
    &:active {
      background-color: $focus-bg;
      border-color: $focus-border;
      color: $focus-color;
    }
    &:focus {
      color: $focus-color;
    }
  } @else {
    &:hover,
    &:active {
      background-color: $bgcolor;
      border-color: $border-color;
      color: $color;
    }
    &:focus {
      color: $color;
    }
  }
}

@mixin link-styles-optional-color($color, $hover-color: null, $focus-color: null) {
  color: $color;

  @if ($hover-color != null and $focus-color != null) {
    &:hover {
      color: $hover-color;

      .admin-link-icon {
        color: $hover-color;
      }
    }

    &:focus,
    &:active {
      color: $focus-color;

      .admin-link-icon {
        color: $focus-color;
      }
    }
  } @else {
    &:focus,
    &:hover,
    &:active {
      cursor: pointer;
      color: $color;
      text-decoration: underline;

      .admin-link-icon {
        color: $color;
      }
    }
  }

  &-icon {
    color: $color;
  }
}

@mixin themed-section($theme-name, $t-section-bg, $t-text-color, $t-link-color, $t-link-color--hover) {
  .#{$theme-name} {
    &.section-body {
      background: $t-section-bg;
    }

    .section-title {
      color: $t-text-color;
    }

    .section-link-blk {
      color: $t-link-color;

      &:hover {
        fill: $t-link-color--hover;

        .section-link-blk__icon {
          fill: $t-link-color--hover;
        }
      }

      &__icon {
        fill: $t-link-color;
      }
    }

    .admin-icon {
      color: $t-text-color;
    }
  }
}

@mixin link-styles {
  color: v.$accessible-teal;
  text-decoration: none;

  &:focus,
  &:hover,
  &:active {
    cursor: pointer;
    text-decoration: underline;
  }

  &:hover {
    color: v.$accessible-teal--hover;
  }

  &:focus,
  &:active {
    color: v.$accessible-teal--active;
  }
}

@mixin learn-more-link-size {
  font-size: 14px;
  line-height: 18px;
}

//TODO: transform next 2 mixins in 1
@mixin card-options {
  .card-options {
    position: absolute;
    bottom: 100%;
    left: -3px;
    display: none;
    align-items: center;
    column-gap: 12px;
    padding: 3px 14px;
    background-color: #26890d;

    &--bottom {
      top: 0;
      bottom: auto;
    }

    &__option-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 26px;
      height: 26px;
      border: none;
      background: transparent;
      cursor: pointer;
      position: relative;

      &:hover {
        background: rgba(0, 0, 0, 0.08);
      }

      &:first-child {
        &:after {
          content: '';
          display: block;
          width: 1px;
          background: #ffffff;
          opacity: 0.2;
          height: 12px;
          position: absolute;
          right: -7px;
        }
      }

      &:last-child {
        margin-left: 12px;

        &:before {
          content: '';
          display: block;
          width: 1px;
          background: #ffffff;
          opacity: 0.2;
          height: 12px;
          position: absolute;
          left: -17px;
        }
      }

      &--drag {
        cursor: move;
      }
    }

    &__icon {
      width: 12px;
      height: 12px;
      color: v.$white-color;
      display: flex;
    }
  }
}

@mixin section-options {
  .section-options {
    display: flex;
    align-items: center;
    top: 0;
    position: absolute;
    left: 0;
    background-color: v.$green-color;
    padding: 0 10px 4px;
    z-index: 2;

    &__option-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border: none;
      background: transparent;

      &:hover {
        background: rgba(0, 0, 0, 0.08);
      }
    }

    &__divider {
      display: block;
      width: 1px;
      background: #ffffff;
      opacity: 0.2;
      height: 12px;
      margin: 0 12px;
    }

    &__icon {
      width: 14px;
      height: 14px;
      color: v.$white-color;
    }

    &__selector {
      margin-right: 8px;
      display: block;
    }
  }
}

@mixin scrollbars($track-width: 5px, $track-radius: 3px, $thumb-radius: 10px, $thumb-background: v.$btn-grey-color) {
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: $track-radius;
  }

  &::-webkit-scrollbar {
    width: $track-width;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: $thumb-radius;
    background: $thumb-background;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
}

@mixin cursorOnHover($cursor-type) {
  &:hover {
    cursor: $cursor-type;
  }
}
