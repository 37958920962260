@use 'base/variables' as v;
@use 'abstract/mixins' as m;

.resources {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(3, minmax(0, 1fr));

  .preview-mode & {
    .app-tablet & {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .app-mobile & {
      grid-template-columns: 1fr;
    }
  }

  .resource {
    display: flex;
    background-color: v.$preview-bg-color;
    flex-basis: 32%;
    height: 76px;
    color: v.$black-color;
    min-width: 0;
    @include m.cursorOnHover(pointer);

    &:hover {
      .resource-tile {
        background-color: v.$light-blue-res;
      }
    }

    .black &,
    .gradient & {
      box-shadow: 0 0 0 1px v.$preview-bg-color;
    }

    &-icon {
      flex: 0 0 76px;
      background-color: v.$black-color;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;

      app-icon,
      img {
        width: 24px;
        height: 24px;
      }
    }

    &-tile {
      padding: 0 18px;
      flex: 1;
      min-width: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &-title {
        font-size: 18px;
        line-height: 24px;
        font-weight: 600;
        @include m.ellipsis-multiline(64px, 2);
      }

      &-subtitle {
        font-size: 12px;
        line-height: 16px;
        color: v.$dark-grey-color;
        text-decoration: none;
        margin-top: 1px;

        @include m.ellipsis;
      }
    }
  }
}
